export const environment = {
  production: false,
  host:"https://testing.docsort.721logistics.com/api",
  redirect:"https://testing.docsort.721logistics.com/home",
  fl:{
    url:"https://jkfreshlook.com/api/v1/get_data.php",
    u: "docsort",
    k: "V8U26F9JJEWRXD1HFWMB9S1BLISY4UW8",
    g: "doc_sort",
    sg : "entry_data"
  }
};
