
        <ng-template #eaContent>
            <ul class="list-group">
                <li class="list-group-item p-0" *ngFor="let e of ea">
                    <div class="badge badge-warning">
                        <span>{{e.entry.broker_number}}</span>
                        <a href="#" (click)="entryRemove(e,$event)" class="entry-remove badge"><i class="fa fa-remove"></i></a>
                    </div>
                </li>
            </ul>
        </ng-template>

        <a class="btn btn-sm btn-outline-warning" placement="bottom" [ngbPopover]="eaContent" container="body" triggers="mouseenter" [autoClose]="false">
            <span>Entries</span> <span>({{ea.length}})</span> :
        </a>
    