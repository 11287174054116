
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from '@angular/common/http';


export class DocumentResult{
  id:string;
  name:string;
  path:string;
  type:string;
}

export class FolderResult{
  id:string;
  path:string;
  documents: Array<DocumentResult>;
}


@Injectable()
export class OpenkmDataService{
  
  private host:string;
  
  constructor(private http: HttpClient){
    this.host = environment.host;
  }
  
  
  public get(page:string, filter?){}
  
  public find(page:string){}
  
  
  public getBrokerFolder(path:string){
    return this.http.get<FolderResult>(this.host + "/okm/folder/"+path)
      .toPromise()
      //.then((res) =>{ console.log(res); return (res.status == 404) ? null : <FolderResult> res.json(); })
      .catch(this.err)
      ;
  }
  
  
  public getDocumentData(id:string){
    return this.http.get(this.host + "/okm/filedata/"+id,{responseType: "blob"})
      .toPromise()
      .catch(this.err)
      ;
    
  }
  
  
  public getDocumentDataUrl(id:string){
    return this.host + "/okm/filedata/"+id;
  }
  
  private err(error: any): Promise<any> {
    //console.error('An error occurred', error);
    //return Promise.reject(error.message || error);
    //return error.json();
    return null;
  }
  
}
