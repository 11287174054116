<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top hidden-print">
  <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <a class="navbar-brand" href="#"><i class="fa fa-cog" aria-hidden="true"></i></a>

  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">

      <li class="nav-item">
        <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
      </li>

    </ul>
  </div>


  <div class="navbar-collapse collapse w-100 order-2 dual-collapse2" >
    <!-- <notifier-container></notifier-container> -->
    <ul class="navbar-nav ml-auto">

      <li class="nav-item pull-right" *ngIf="isLoggedIn">
        <span class="text-white-50 text-center nav-link p-0" title="{{userInfo?.name}}">
          <span *ngIf="hasPhoto" >
            <img *ngIf="isLoggedIn" src="{{userPhoto}}" class="rounded-circle border border-secondary" style="width: 35px; height: 35px;" alt="{{userInfo?.name}}"/>
          </span>

          <span style="line-height: 34px;">{{userInfo?.name}}</span>
        </span>
      </li>

      <li class="nav-item" *ngIf="isLoggedIn">
        <a class="nav-link" href="#" (click)="logout()">Logout </a>
      </li>

      <li class="nav-item" *ngIf="!isLoggedIn">
        <button class="btn btn-sm btn-primary" (click)="login()"><i class="fa fa-user-plus"></i> Login </button>
      </li>
      <li class="nav-item">
        <button class="btn btn-sm btn-secondary" (click)="resetLayout()" title="Reset Layout View"><i class="fa fa-recycle"></i></button>
      </li>
    </ul>
  </div>
</nav>
