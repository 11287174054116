import {EntryAssignment, EntryAssignmentContext} from "../mailbox-component/mailbox.service";

export class AssignmentContext {
  
  
  /**
   *
   * @param entryAssignment
   */
  public static containers(entryAssignment: EntryAssignment) {
    
    let data: Array<EntryAssignmentContext> = [];
    
    entryAssignment.entry.container_group.forEach((c) => {
      data.push({
        'id': c.value + "-" + entryAssignment.entry.broker_number,
        'context': 'container',
        'text': c.value + " (" + entryAssignment.entry.broker_number + ")",
        'broker': entryAssignment.entry.broker_number,
        'entry_id': entryAssignment.entry.entry_id,
        'ref': c.value,
        'ref_id': c.id
      });
    });
    
    return data;
  }
  
  
  /**
   *
   * @param entryAssignment
   */
  public static bills(entryAssignment: EntryAssignment) {
    
    let data: Array<EntryAssignmentContext> = [];
    
    entryAssignment.entry.bill_group.forEach((b) => {
      data.push({
        'id': b.value + "-" + entryAssignment.entry.broker_number,
        'context': 'bol',
        'text': b.value + " (" + entryAssignment.entry.broker_number + ")",
        'broker': entryAssignment.entry.broker_number,
        'entry_id': entryAssignment.entry.entry_id,
        'ref': b.value,
        'ref_id': b.id
      });
    });
    
    return data;
  }
  
}
