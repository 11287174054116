import {AfterContentInit, Component, Input, OnInit} from "@angular/core";
import * as canvasDatagrid from 'canvas-datagrid';
import * as XLSX from 'xlsx';

@Component({
	selector: 'excel-view',
	template: `
		<div style="max-height: 800px; overflow-y: scroll;">
			<div id="excelView"></div>
		</div>
	`
})
export class ExcelViewModal implements OnInit, AfterContentInit{

	@Input()
	workbook: XLSX.WorkBook

	ngAfterContentInit(): void {

		console.log(this.workbook);

		let sh = this.workbook.Sheets[this.workbook.SheetNames[0]];

		let grid = canvasDatagrid({
			parentNode: document.getElementById('excelView'),
			//data: XLSX.utils.sheet_to_json(sh)
			//data:sh
		});

		grid.data = XLSX.utils.sheet_to_json(sh);
		//grid.style.height = '100%';
		//grid.style.width = '100%';
	}

	ngOnInit(): void {

	}

}
