import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {EntryResult} from "../freshlook-data/freshlook-data.service";
import {HttpClient} from '@angular/common/http';


export interface MailItem {
    id: string;
    datetime: string;
    domain: string;
    path: string;
    status: string;
    commit_mode: CommitMode;
    vessel: string;
    voyage: string;
    bol: string;
    container: string;
    revised: boolean;
    hidden: boolean;
    mail_date: string;
    mail_time: string;
    mail_from: string;
    mail_to: Array<string>;
    mail_cc: Array<string>;
    mail_subject: string;
    mail_body: string;
    mail_folder: string;
    mime_type: string,
    mail_id: string;
    item_id: Array<string>;
    document_body: string;
    document_name: string;
    document_type: string;
    division: MailDivision;
    entry_assignment: Array<EntryAssignment>;
    page_splits: Array<PageItem>;
}

export enum CommitMode {
    ORIGINAL = "ORIGINAL",
    PAGE = "PAGE"
};


export enum MailDivision {
    DocsJKFE            = "DocsJKFE",
    Docs721             = "Docs721",
    DocsJKFWest         = "DocsJKFWest",
    DocsWestArrivals    = "DocsWestArrivals",
    DocsWestChile       = "DocsWestChile",
    DocsWestPeru        = "DocsWestPeru",
    DocsWestAir         = "DocsWestAir",
    DocsWestTropical    = "DocsWestTropical",
    DocsWestNZAustralia = "DocsWestNZAustralia",
    DocsWestItaly       = "DocsWestItaly",
    DocsWestHouston     = "DocsWestHouston",
    DocsWestNonPerishable="DocsWestNonPerishable",
    DocsWestExams       = "DocsWestExams"
}

export interface PageItem {
    id: string;
    index:number;
    img_path: string;
    pdf_path: string;
    excel_path:string;
    thumb_path: string;
    document_type: string;
    mime_type: string;
    revised: boolean;
    hidden: boolean;
    combine_items: Array<PageItem>;
    entry_assignment: Array<EntryAssignment>;
    selected: boolean;
}


export interface SelectedMailItem{
    item:MailItem;
    grouped:boolean;
}


export interface CommitValidationResponse{
    valid:boolean;
    errors:Array<string>;
    datetime:Date;
}


export interface MailboxListSort {
    direction: string;
    property: string;
    ignoreCase: boolean;
    nullHandling: string;
    ascending: boolean;
    descending: boolean;
}


export interface MailboxListResponse {
    content: Array<MailItem>;
    totalPages: number;
    totalElements: number;
    numberOfElements: number;
    last: boolean,
    sort: Array<MailboxListSort>;
    size: number;
    number: number;
}


export interface PageCombineRequest {
    page_id: string;
    ref_id: string
}

export interface PageUncombineRequest {
    page_id: string;
    remove_id: string;
}


export interface ItemStatusRequest{
    itemId:Array<string>;
    status:string;
    grouped:boolean
}


export interface ItemDivisionRequest{
    itemId:Array<string>;
    division:string;
    grouped:boolean
}


export class EntryAssignment {
    context: string;
    data: Array<EntryAssignmentContext>
    entry: EntryResult;


    /**
     *
     * @param context
     * @param entry
     */
    constructor(context: string, entry: EntryResult) {
        this.context = context;
        this.entry = entry;
    }

    /**
     * Ugly workround to ensure we get a unique object and not a reference
     * when adding to a entry assignment
     *
     * @param e
     */
    public static copy(e: EntryAssignment): EntryAssignment {
        return Object.assign({}, JSON.parse(JSON.stringify(e)));
    }
}


export interface EntryAssignmentContext {
    id: string; // Unique string for dropdown selection
    context: string; // Are we holding container info or bol
    text: string; // Display text for dropdown selection
    broker: string;
    entry_id: number;
    ref: string; // Container or BL number
    ref_id: number;
}


export interface EntryAssignmentRequest {
    id: string;
    assignment: EntryAssignment;
}


export interface EntryAssignmentBulkRemoveRequest {
    id: string;
    grouped:boolean;
    fileNumber:string;
}


export interface FilterFieldItem{field:string, value:any}


export class FilteredListRequest {
    status: Array<string> = ["PENDING"];
    filter: Array<FilterFieldItem> = [];
    term: string = null;
    page: number = 0;
    sort: string = null;
    dir: string = "DESC";
    grouped: boolean = false;

    public clear() {
        this.status = ["PENDING"];
        this.filter = [];
        this.term = null;
        this.page = 0;
        this.sort = null;
        this.dir = "DESC";
    }

    public setStatus(status: Array<string>) {
        this.status = status;
        this.resetPage();
    }


    public addStatus(status: string) {
        this.status.push(status);
        this.resetPage();
    }

    public addFieldFilter(filter: FilterFieldItem) {
        this.filter.push(filter);
        this.resetPage();
    }

    public addStatusFilter(status: string) {
        this.status.push(status);
    }

    public changeDir() {
        this.dir = (this.dir == "DESC") ? "ASC" : "DESC";
    }

    public resetPage(){
        this.page = 0;
    }

    public nextPage() {
        this.page++;
    }

    public prevPage() {
        this.page--;
    }
}


export interface AuditLog{
    id:string;
    refId:string;
    grouped:boolean;
    context:string;
    mailSubject:string;
    action:string;
    oldValue:string;
    value:string;
    user:string;
    dateTime:Date;
}


@Injectable()
export class MailboxDataService {

    host: string;

    /**
     *
     * @param {Http} http
     */
    constructor(private http: HttpClient) {
        this.host = environment.host;
    }


    /**
     *
     * @param {number} page
     * @param filter
     * @returns {Promise<any | never | MailboxListResponse>}
     */
    public get(page: number, filter?: string) {

        return this.http.get<MailboxListResponse>(`${this.host}/mailbox/all/${page}`, {params: {term: filter}})
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param req
     */
    public getData(req: FilteredListRequest) {

        return this.http.post<MailboxListResponse>(`${this.host}/mailbox/search/`, req);
    }


    /**
     *
     * @param id
     */
    public getById(id:string, grouped:boolean=false){

        let g = (grouped && grouped == true) ? "?grouped=true" : "";

        return this.http.get<MailItem>(`${this.host}/mailbox/item/${id+g}`);
    }




    /**
     *
     * @param id
     */
    public getAuditLog(id:string, grouped:boolean=false){

        let g = (grouped && grouped == true) ? "?grouped=true" : "";

        return this.http.get<AuditLog>(`${this.host}/mailbox/audit/${id+g}`)
            .toPromise()
            .catch(this.err)
            ;
    }




    /**
     *
     * @param id
     */
    public getByMailId(id:string){

        let _id = encodeURIComponent(id);

        return this.http.get<MailItem>(`${this.host}/mailbox/item-group?mailId=${_id}`)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param req
     */
    public setStatus(req:ItemStatusRequest) {

        return this.http.post(`${this.host}/mailbox/status/`, req)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param req
     */
    public setDivision(req:ItemDivisionRequest){
        return this.http.post(`${this.host}/mailbox/division/`, req)
            .toPromise()
            .catch(this.err)
            ;
    }



    /**
     *
     * @param {string} term
     * @returns {Promise<any | never | MailboxListResponse>}
     */
    public find(term: string) {
        return this.http.post(`${this.host}/mailbox/find/`, {term: term})
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {EntryAssignmentRequest} assignment
     * @returns {Promise<any | never | Response>}
     */
    public assignEntry(assignment: EntryAssignmentRequest) {
        return this.http.post(`${this.host}/mailbox/assign/`, assignment)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {{id: string; entry: EntryResult}} data
     * @returns {Promise<any | never | Response>}
     */
    public removeEntry(data: { id: string, entry: EntryResult }) {
        return this.http.post(`${this.host}/mailbox/remove-assignment/`, data)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {EntryAssignmentRequest} assignment
     * @returns {Promise<any | never | Response>}
     */
    public assignEntryToPage(assignment: EntryAssignmentRequest) {
        return this.http.post<PageItem>(`${this.host}/mailbox/page-assign/`, assignment)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {{id: string; entry: EntryResult}} data
     * @returns {Promise<any | never | Response>}
     */
    public removeEntryFromPage(data: { id: string, entry: EntryResult }) {
        return this.http.post(`${this.host}/mailbox/remove-page-assignment/`, data)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param data
     */
    public removeEntryFromAllPages(data: EntryAssignmentBulkRemoveRequest) {
        return this.http.post(`${this.host}/mailbox/remove-page-assignment-all/`, data)
            .toPromise()
            .catch(this.err)
            ;
    }



    /**
     *
     * @param id
     * @param ea
     */
    public updateMailItemAssignment(id: string, ea: Array<EntryAssignment>) {
        return this.http.post(`${this.host}/mailbox/update-assignment/${id}`, ea)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param id
     * @param ea
     */
    public updatePageItemAssignment(id: string, ea: Array<EntryAssignment>) {
        return this.http.post(`${this.host}/mailbox/update-page-assignment/${id}`, ea)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {string} id
     * @returns {Promise<any | Response>}
     */
    public removeMailItem(id: string) {
        return this.http.post(`${this.host}/mailbox/remove/${id}`, null)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     */
    public removeAllMailItems() {
        return this.http.post(`${this.host}/mailbox/removeall/`, null)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {string} id
     * @returns {Promise<any | never | Response>}
     */
    public removeMailItemPage(id: string) {
        return this.http.post(`${this.host}/mailbox/remove-page/${id}`, null)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {PageCombineRequest} req
     * @returns {Promise<any | never | Response>}
     */
    public combine(req: PageCombineRequest) {
        return this.http.post(`${this.host}/mailbox/combine/`, req)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     * @param {PageUncombineRequest} req
     * @returns {Promise<any | never | Response>}
     */
    public uncombine(req: PageUncombineRequest) {
        return this.http.post(`${this.host}/mailbox/uncombine/`, req)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param error
     * @returns {Promise<any>}
     */
    private err(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }


    /**
     *
     * @param id
     */
    public getPageData(id: string){
        return this.http.get(`${this.host}/mailbox/pagedata/${id}`,{responseType: "blob"}).toPromise().catch(this.err);
    }


    /**
     *
     * @param id
     */
    public getExcelData(id: string){
        return this.http.get(`${this.host}/mailbox/exceldata/${id}`,{responseType: "blob"}).toPromise().catch(this.err);
    }


    /**
     *
     * @param id
     */
    public getExcelDataUrl(id: string){
        let h = this.host.replace("api","io")
        return `${h}/excel/${id}`
    }


    /**
     *
     * @param {string} id
     * @returns {string}
     */
    public imgDataUrl(id: string): string {
        return `${this.host}/mailbox/imgdata/${id}`;
    }


    /**
     *
     * @param id
     */
    public getImgData(id: string){
        return this.http.get(`${this.host}/mailbox/imgdata/${id}`,{responseType: "blob"}).toPromise().catch(this.err);
    }



    public getThumbData(id: string, grouped:boolean){

        let g = (grouped && grouped == true) ? "?grouped=true" : "";

        return this.http.get<Array<{id:string,data:string}>>(`${this.host}/mailbox/thumbs/${id+g}`);
    }


    /**
     *
     * @param {string} item_id
     * @param {string} doc_type
     * @returns {Promise<any | never | Response>}
     */
    public setDocType(item_id: string, doc_type: string) {
        return this.http.post(`${this.host}/mailbox/doctype`, {id: item_id, type: doc_type})
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {string} item_id
     * @param {string} doc_type
     * @returns {Promise<any | never | Response>}
     */
    public setPageDocType(item_id: string, doc_type: string) {
        return this.http.post(`${this.host}/mailbox/pagetype`, {id: item_id, type: doc_type})
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @returns {Promise<any | never | Response>}
     */
    public getDocTypes() {
        return this.http.get(`${this.host}/mailbox/doctype`).toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {string} id
     * @returns {string}
     */
    public getMailBodyUrl(id: string) {
        return `${this.host}/mailbox/mailbody/${id}`;
    }


    /**
     *
     * @param {string} id
     * @returns {Promise<any | never | string>}
     */
    public getMailBody(id: string) {
        return this.http.get(`${this.host}/mailbox/mailbody/${id}`)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @returns {Promise<any | never | Response>}
     */
    public commit() {
        return this.http.post(`${this.host}/mailbox/commit`, null)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param id
     */
    public commitItemNow(id: string, grouped:boolean=false) {

        let g = (grouped && grouped == true) ? "?grouped=true" : "";

        return this.http.post<CommitValidationResponse>(`${this.host}/mailbox/commit-item/${id+g}`, null);
    }


    /**
     *
     * @param id
     * @param revised
     */
    public setMailItemRevised(id: string, revised: boolean) {
        return this.http.post(`${this.host}/mailbox/item-revised/${id}/${revised}`, null)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param id
     * @param revised
     */
    public setPageRevised(id: string, revised: boolean) {
        return this.http.post(`${this.host}/mailbox/page-revised/${id}/${revised}`, null)
            .toPromise()
            .catch(this.err)
            ;
    }


  /**
   *
   * @param id
   * @param revised
   */
    public setMailItemHidden(id: string, revised: boolean) {
        return this.http.post(`${this.host}/mailbox/itemhidden/${id}/${revised}`, null)
            .toPromise()
            .catch(this.err)
            ;
    }


  /**
   *
   * @param id
   * @param revised
   */
    public setPageHidden(id: string, revised: boolean) {
        return this.http.post(`${this.host}/mailbox/pagehidden/${id}/${revised}`, null)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param {{id: string; mode: string}} req
     * @returns {Promise<any | never | Response>}
     */
    public setCommitMode(req: { id: string, mode: string }) {
        return this.http.post(`${this.host}/mailbox/commit-mode`, req)
            .toPromise()
            .catch(this.err)
            ;
    }

    /**
     *
     * @param {string} id
     * @returns {Promise<any | never | Response>}
     */
    public getPageSplits(id: string) {
        return this.http.get(`${this.host}/mailbox/splits/${id}`,)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param id
     */
    public doPageSplit(id: string) {
        return this.http.post(`${this.host}/mailbox/resplit/${id}`, null)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param status
     */
    public getDomainGroupCount(status?: string) {

        let url = `${this.host}/mailbox/count/domain/`;

        if (status) {
            url = url.concat(status);
        }

        return this.http.get<Array<{ domain: string, count: number }>>(url)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param status
     */
    public getMailDateGroupCount(status?: string) {

        let url = this.host + "/mailbox/count/maildate/";

        if (status) {
            url = url.concat(status);
        }

        return this.http.get<Array<{ mail_date: string, count: number }>>(url)
            .toPromise()
            .catch(this.err)
            ;
    }


    /**
     *
     * @param status
     */
    public getMailFolderGroupCount(status?: string) {

        let url = this.host + "/mailbox/count/mailfolder/";

        if (status) {
            url = url.concat(status);
        }

        return this.http.get<Array<{ mail_folder: string, count: number }>>(url)
            .toPromise()
            .catch(this.err)
            ;
    }
}
