
<div style="overflow-y:scroll;height:100%">

  <table id="entry-list" class="table table-condensed table-striped table-bordered hidden">
    <thead class="">
    <tr>
      <th>Save</th>
      <th>Broker#</th>
      <th>Entry #</th>
      <th>BOL</th>
      <th>Container</th>
      <th>Customer</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let e of eaHistory">
      <td>
        <button *ngIf="e.entry.broker_number !== null" (click)="entrySelected(e)" class="btn btn-sm btn-secondary" title="Assign to:"><i class="fa fa-history "></i></button>
      </td>
      <td>
        <a href="#" (click)="brokerClick($event,e.entry)">{{e.entry.broker_number}}</a>
      </td>
      <td nowrap>
        <span>{{e.entry.entry_number}}</span><br/>
        <span class="badge badge-primary">{{e.entry.company}}</span>&nbsp;
        <span class="badge badge-info">{{e.entry.division}}</span> &nbsp;
        <span class="badge badge-danger" *ngIf="e.entry.ff" title="Freight Entry">F</span>
      </td>
      <td>
        <pre>{{split(e.entry.bol)}}</pre>
      </td>
      <td>
        <pre>{{split(e.entry.container)}}</pre>
      </td>
      <td>{{e.entry.short_name}}</td>
    </tr>
    </tbody>
  </table>
</div>
