import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MailDivision, MailItem} from './mailbox.service';


/**
 *
 * @param item
 */
export function isIsfMail(item: MailItem) {

  let fields = []
      .concat(item.mail_to.map(i => i.toLowerCase()))
      .concat(item.mail_cc.map(i => i.toLowerCase()));

  if (fields.includes('isf@jkfresheast.com')) {
    return true;
  }

  const rx = /(10\+2|isf|ifs)/ig;

  if (item.mail_subject.match(rx)) {
    return true;
  }

  if(item.mail_body.match(rx)) { return true; }

  return false;
}



@Component({
  selector: 'status-select',
  template: `
    <select title="Document Status" class="form-control form-control-sm doc-status" (change)="selected($event)" [value]="selectedItem">
      <option *ngFor="let i of items;" [value]="i.value">{{i.label}}</option>
    </select>
  `
})
export class MailItemStatusSelect implements OnInit {

  @Input()
  selectedItem = 'PENDING';

  @Output()
  selectedItemChange:EventEmitter<string> = new EventEmitter<string>();

  @Input()
  allOption = false;

  public items = [
    {value: 'PENDING',         label: 'Pending'},
    {value: 'PRIORITY',        label:'Priority'},
    {value: 'HOLD',            label: 'Hold'},
    {value: 'BULK',            label: 'Bulk'},
    {value: 'RECEIPTS',        label: 'Receipts'},
    //{value: 'WEST_COAST',      label: 'West Coast'},
    {value: 'ISF',             label: 'ISF'},
    {value: 'ARRIVAL_NOTICE',  label: 'Arrivals'},
    {value: 'ARRIVAL_NOTICE_REVISED',  label: 'Arrivals (Rev)'},
    {value: 'AIR',             label: 'Air'},
    {value: 'FREIGHT',         label: 'Freight'},
    {value: 'CANADA',          label: 'Canada'},
    {value: 'COMPLETE',        label: 'Complete'},
    {value: 'REMOVE',          label: 'Remove'}
  ];

  ngOnInit(): void {
    if (this.allOption) {
      this.items.push({value: 'ALL', label: 'All'});
    }
  }


  selected(ev) {
    this.selectedItem = ev.target.value;
    this.selectedItemChange.emit(ev.target.value);
  }

}


@Component({
  selector:'division-select',
  template:`
    <select title="Document Division" class="form-control form-control-sm division-status" (change)="selected($event)">
      <option *ngFor="let d of getDivisions();" [value]="d" [selected]="d == selectedItem">{{d}}</option>
    </select>
  `,
  styleUrls: ['./mailbox-component.component.css'],
})
export class MailItemDivisionSelect implements OnInit{

    division:MailDivision;

    @Input()
    selectedItem = MailDivision.DocsJKFE;

    @Output()
    divisionSelected: EventEmitter<MailDivision> = new EventEmitter();

    ngOnInit(): void {

    }


    getDivisions(){
      return Object.keys(MailDivision);
    }


    selected(e){
      this.selectedItem = e.target.value;
      this.divisionSelected.emit(this.selectedItem);
    }
}


@Component({
  selector: 'mailitem-sort',
  template: `
    <div>
      <a href="#" class="badge badge-secondary" style="font-size: 10px" (click)="sortChange($event)">
        <span>Sort &nbsp;</span>
        <strong><i [attr.class]="desc == true ? 'fa fa-arrow-circle-down' : 'fa fa-arrow-circle-up' " ></i></strong>
      </a>
    </div>`
})
export class MailItemSorting implements OnInit {

  desc = true;

  @Output()
  sortDirectionChange: EventEmitter<{direction: string}> = new EventEmitter();

  ngOnInit(): void {}

  sortChange(ev: Event) {

    ev.preventDefault();

    this.desc = !this.desc;

    this.sortDirectionChange.emit({
      'direction' : (this.desc) ? 'DESC' : 'ASC'
    });
  }

}
