import {Component, Inject, OnInit} from "@angular/core";
import {AuditLog, EntryAssignment, MailboxDataService, MailItem, SelectedMailItem} from "./mailbox.service";
import {GoldenLayoutComponentState, GoldenLayoutContainer} from "@embedded-enterprises/ng6-golden-layout";
import * as GoldenLayout from "golden-layout";


@Component({
	selector: 'auditlog-viewer',
	template: `
        <div style="max-height: 400px; overflow-x: scroll">
			<div>
                <span>{{item?.id}}</span>
				<span (click)="getData()" class="pull-right"><i class="fa fa-recycle"></i></span>
			</div>
			
            <table class="table table-sm table-bordered table-striped  table-warning small" *ngIf="auditData.length > 0">
                <thead>
                <tr>
                    <th>RefId</th>
                    <th>Action</th>
                    <th>Old</th>
                    <th>Value</th>
                    <th>User</th>
                    <th>Datetime</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let d of auditData;">
                    <td>{{d.refId}}</td>
                    <td>{{d.action}}</td>
                    <td>{{d.oldValue}}</td>
                    <td>{{d.value}}</td>
                    <td>{{d.user}}</td>
                    <td>{{d.dateTime | date:'medium'}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    `
})
export class AuditLogViewer implements OnInit{

	public item: MailItem;

	public grouped:boolean = false;

	public auditData:Array<AuditLog> = [];

	constructor(private mb: MailboxDataService,
				@Inject(GoldenLayoutComponentState) public state: any,
				@Inject(GoldenLayoutContainer) private container: GoldenLayout.Container) {}


	ngOnInit(): void {

		this.container.layoutManager.eventHub.on('mailItemSelect', (e: SelectedMailItem) => {
			this.item = e.item;
			this.grouped = e.grouped;
			this.getData();
		});

		this.container.layoutManager.eventHub.on('entrySelect', (ea: EntryAssignment) => {
			this.getData();
		});
	}


	getData(){
		this.mb.getAuditLog(this.item.id,this.grouped).then((auditData) => {
			this.auditData = auditData;
		});
	}

}
