import {
    MailboxDataService,
    MailboxListResponse,
    MailItem,
    FilteredListRequest,
    FilterFieldItem, SelectedMailItem, ItemStatusRequest, ItemDivisionRequest
} from '../mailbox.service';
import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, Inject} from '@angular/core';
import {isIsfMail, MailItemStatusSelect} from '../mailbox.component';
import {MailboxFilter} from './mailbox.filter';
import {EntryAssignContextComponent} from '../../entry-assign-context/entry-assign-context.component';
import * as moment from 'moment';
import {GoldenLayoutComponentState, GoldenLayoutContainer} from "@embedded-enterprises/ng6-golden-layout";
import * as GoldenLayout from 'golden-layout';
import {docTypes} from '../mailbox.model';


@Component({
    selector:"item-list",
    templateUrl:"mailbox.list.html",
    styleUrls:["mailbox.list.css"]
})
export class MailboxList implements OnInit{

    public data: MailboxListResponse;

    @ViewChild('status', {static : true})
    itemStatus: MailItemStatusSelect;

    @ViewChild('mailfilter', { static: true })
    mailfilter: MailboxFilter;

    @ViewChildren('multisel')
    multiMoveItem: QueryList<ElementRef>;

    @ViewChild('eacontext')
    eacontext: EntryAssignContextComponent;

    docTypes:Array<{value:string,key:string}>;

    public filter: string = null;

    public listFilter:FilteredListRequest = new FilteredListRequest();

    totalPages = 0;
    totalElements = 0;
    numberOfElements = 0;
    size = 0;
    page = 0;

    sort = 'DESC';

    selected: MailItem = null;

    selectedItemId: string;

    isSearching = false;

    isFiltering = false;

    multiSelect = false;

    optionsOpen = false;


    /**
     *
     * @param mb
     * @param state
     * @param gcontainer
     */
    constructor(private mb: MailboxDataService, 
        @Inject(GoldenLayoutComponentState) public state: any,
        @Inject(GoldenLayoutContainer) private gcontainer: GoldenLayout.Container) {

    }


    /**
     *
     */
    ngOnInit(): void {

        if(localStorage.getItem("ds.filter.state") !== null){
            let f = localStorage.getItem("ds.filter.state");
            this.listFilter = Object.assign(new FilteredListRequest(),JSON.parse(f));

            console.log("cached filter ",this.listFilter);
        }

        this.docTypes = docTypes;

        this.getData();
    }


    /**
     *
     */
    getData(){

        localStorage.setItem("ds.filter.state",JSON.stringify(this.listFilter));

        this.isSearching = true;

        this.mb.getData(this.listFilter).subscribe((res) => {
            this.handleResponse(res);
            this.isSearching = false;
        });
    }


    /**
     *
     * @param filters
     */
    filterField(filters: Array<FilterFieldItem>) {

        this.isFiltering = true;

        this.listFilter.resetPage();

        this.listFilter.filter = filters;

        this.getData();

    }


    /**
     *
     * @param ev
     */
    filterMailItems(ev: KeyboardEvent) {

        if (ev.code != 'Enter' && ev.code != 'NumpadEnter') {
            return;
        }

        if (this.listFilter.term !== null && this.listFilter.term.length >= 3) {
            this.isFiltering = true;
            //this.isSearching = true;
            this.listFilter.resetPage();
            //this.getData();
        }

    }


    /**
     *
     * @param status
     */
    mailboxListStatusSelected(status: string) {

        this.mailfilter.refresh(status);

        this.listFilter.setStatus([status]);
        this.selected = null;

        this.gcontainer.layoutManager.eventHub.emit("statusChange");
        //this.getData();

    }


    /**
     * Toggle grouping
     *
     * @param ev
     */
    listGroupChange(ev: Event) {

        ev.preventDefault();

        this.listFilter.grouped = !this.listFilter.grouped;

        this.getData();
    }


    /**
     *
     * @param ev
     */
    multiSelectChange(ev) {

        let itemId =  this.multiMoveItem
            .filter((e) => e.nativeElement.checked)
            .map((e) => (this.listFilter.grouped) ?
                    this.data.content[e.nativeElement.value].mail_id :
                    this.data.content[e.nativeElement.value].id);

        if(itemId.length == 0){ return; }

        if(!confirm("Are you sure you want to move all these to "+ev + "?")){
            return false;
        }

        let req = <ItemStatusRequest>{
            itemId : itemId,
            status : ev,
            grouped : this.listFilter.grouped
        };

        this.mb.setStatus(req).then((res) => {
            this.multiSelect = false;
            this.getData();
            req = null;
            itemId = [];
        });

    }


    /**
     *
     */
    getCurrentStatus() {
        if (!this.itemStatus) {
            return 'PENDING';
        }
        return this.itemStatus.selectedItem;
    }


    /**
     *
     * @param s
     */
    sortChange(s: {direction: string}) {
        this.sort = s.direction;

        this.listFilter.changeDir();

        this.getData();
    }


    /**
     *
     * @param p
     */
    pageChange(p) {
        this.listFilter.page = p;
        this.getData();
    }


    /**
     *
     */
    clearFilter() {
        this.mailfilter.clear();
        this.listFilter.clear();
        this.isFiltering = false;
        this.getData();
    }


    /**
     *
     * @param item
     */
    clearFromList(item: MailItem){
        this.data.content = this.data.content.filter(m => m.id != item.id);
        this.selected = null;
    }


    /**
     *
     * @param item
     */
    listSelection(item) {

        if (this.selected == null) {return false; }

        return (this.selectedItemId == item.id);
    }


    /**
     *
     * @param item
     * @param status
     * @param index
     */
    mailItemStatusSelected(item: MailItem, status: any, index: number) {

        /**
         * Inform the user the selected email is ISF related when movong to the ISF status
         */
        if (status == 'ISF') {
            if (isIsfMail(item)) {
                if (confirm('This is a ISF related email, continue move?') == false) {
                    return;
                }
            }
        }

        let req = <ItemStatusRequest>{
            itemId : (this.listFilter.grouped) ? [item.mail_id] : [item.id],
            status : status,
            grouped : this.listFilter.grouped
        };


        this.mb.setStatus(req).then((res) => {
            this.multiSelect = false;
            this.getData();
        });
    }


    /**
     *
     * @param item
     * @param division
     * @param index
     */
    mailItemDivisionSelected(item:MailItem, division:any, index:number){

        this.selected.division = division;

        let req = <ItemDivisionRequest>{
            itemId: (this.listFilter.grouped) ? [item.mail_id] : [item.id],
            division:division,
            grouped:this.listFilter.grouped
        };

        this.mb.setDivision(req).then((r) => {
           this.getData();
        });
    }


    /**
     *
     * @param i
     */
    viewItem(i: number){

        /**
         * select checkbox if in multi select mode
         * instead of display mail item
         */
        if (this.multiSelect) {

            const row = this.multiMoveItem.find(e => e.nativeElement.value == i);

            row.nativeElement.checked = !row.nativeElement.checked;

            return true;
        }

        if (this.selectedItemId == this.data.content[i].id) { return false; }

        this.selectedItemId = this.data.content[i].id;

        this.selected = this.data.content[i];

        let se:SelectedMailItem = {
            item    : this.selected,
            grouped : this.listFilter.grouped
        };

        this.gcontainer.layoutManager.eventHub.emit("mailItemSelect",se);
    }


    /**
     *
     * @param ev
     */
    optionsOpenEvent(ev: Event) {

        ev.preventDefault();

        this.optionsOpen = !this.optionsOpen;
    }


    /**
     *
     */
    commitItemNow() {

        //const pageCommit = (this.selected.page_splits.find((p) => p.entry_assignment.length > 0)) ? true : false;

        this.mb.commitItemNow(this.selected.id,this.listFilter.grouped).subscribe((res) => {

            if(!res.valid){

                alert(res.errors.join("\n"));

                console.error("Not valid",res.errors);

                return false;
            }


            if(res.valid){

                /**
                 * If this an mail item already completed then dont remove
                 * from the current list
                 */
                if(this.selected.status != 'COMPLETE') {
                    this.getData();
                    this.gcontainer.layoutManager.eventHub.emit("itemCommit",res);
                }

            }

        });

    }


    /**
     *
     * @param res
     */
    handleResponse(res: MailboxListResponse){

        this.data = res;
        this.totalPages = res.totalPages;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;
        this.size = res.size;
        this.page = res.number;
        //this.listFilter.page = res.number

        //this.selected = this.data.content[0];
        if(this.data.content.length > 0){
            this.viewItem(0);
        }

    }
}
