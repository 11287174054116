<div style="height: 100%">

    <!---- Page toolbar header ----->
    <div class="pagesplit-header">

        <div *ngIf="viewMode=='pages'">

            <div class="row">

                <div class="col-2">
                    <assigned-entries-removal
                            *ngIf="selectedMailItem !== null "
                            [(ea)]="selectedMailItemEntries"
                            (removeItem)="entryPageItemRemoveAll($event)">
                    </assigned-entries-removal>
                </div>
                <div class="col-8" style="line-height: 32px;">
                    <div class="form-check-inline">
                        <page-check [checked]="chkAll" (change)="selectAllPages()" label="Check All"></page-check>
                    </div>
                    <div class="form-check-inline">
                        <page-check [checked]="chkAllRevised" (change)="selectAllRevised()" label="Revised All"></page-check>
                    </div>
                    <div class="form-check-inline">
                        <page-check [checked]="chkAllHidden" (change)="selectAllHidden()" label="Hidden All"></page-check>
                    </div>
                </div>

                <div class="col-2">
                    <div class="btn-group-sm pull-right">
                        <button class="btn btn-sn btn-secondary" (click)="pageZoom('+')"><i class="fa fa-plus-circle"></i></button>
                        <button class="btn btn-sn btn-secondary" (click)="pageZoom('-')"><i class="fa fa-minus-circle"></i></button>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="viewMode=='view'">

            <div class="form-row">
                <div class="col-2">

                    <!----- When displaying a pdf page item ------>
                    <assigned-entries
                            *ngIf="selectedPage !== null && selectedPage.entry_assignment.length > 0"
                            [ea]="selectedPage.entry_assignment"
                            (removeItem)="entryPageItemRemove($event.entry)">
                    </assigned-entries>

                    <!----- When displaying a non-pdf in view mode ------>
                    <!--
                    <assigned-entries
                            *ngIf="item !== null && item.entry_assignment.length > 0"
                            [ea]="item.entry_assignment"
                            (removeItem)="entryItemRemove(item,$event.entry)">
                    </assigned-entries>
                    -->

                </div>

                <div class="col-6">
                    <entry-assign-context
                            *ngIf="selectedPage !== null"
                            [horizontal]="true"
                            [item]="selectedPage"
                            (entryContextItemChange)="entryAssignmentChange($event)"
                            (pageTypeEvent)="pageDocType($event)"
                            #eacontext>
                    </entry-assign-context>
                </div>

                <div class="col-2">
                    <div class="btn-group btn-group-sm pull-right" *ngIf="selectedPage !== null">
                        <button class="btn btn-sm btn-primary" (click)="pagePrevious()" title="Previous"><i class="fa fa-chevron-left"></i></button>
                        <button class="btn btn-sm btn-primary" (click)="pageForward()" title="Next"><i class="fa fa-chevron-right"></i></button>
                    </div>
                </div>

                <div class="col-2">
                    <button class="btn btn-sm btn-danger pull-right" (click)="closeView()"><i class="fa fa-close"></i></button>
                </div>

            </div>

        </div>


    </div>

    <!---- PDF full view ---->
    <div [hidden]="viewMode == 'pages'" style="height: 100%; background-color: #495057">

        <ng-template #docView>

            <iframe *ngIf="isPdf()" id="doc-view" [src]="docData" width="100%" height="100%" style="min-height: 660px; border: none; height:100%" allowfullscreen scrolling="yes"></iframe>

            <ngx-doc-viewer *ngIf="isExcel()" [url]="excelUrl(selectedPage)" viewer="office" style="width:100%;height:100%;"></ngx-doc-viewer>

        </ng-template>

        <ng-template #docLoading>
            <img src="./assets/loading2.gif" alt="loading" class="page-loading"/>
        </ng-template>


        <div *ngIf="(docDataLoading ); then docLoading; else docView" style="background-color: #495057"></div>

    </div>


    <!---- Page tiles/thumbs ----->
    <div [hidden]="viewMode == 'view'" style="height: 100%;  background-color: #495057;" >

        <div class="pagesplit-view" *ngIf="item !== null">

            <div class="row">
                <div class="col-sm-{{colSize}} page-item" *ngFor="let pageitem of item.page_splits; let i = index;">
                    <page-item
                            [index]="i"
                            [item]="pageitem"
                            [mailItem]="item"
                            [imageData]="thumb(pageitem)"
                            [types]="docTypes"
                            (addToEvent)="combineEvent($event)"
                            (pageUncombineEvent)="uncombineEvent($event)"
                            (pageClickEvent)="pageClick($event)"
                            (pageTypeEvent)="pageDocType($event)"
                            (pageEntryRemove)="entryPageRemove($event)"
                            (excelEntryRemove)="entryItemRemove(item,$event.entry)"
                            #pgitem>
                    </page-item>
                </div>
            </div>

        </div>
    </div>
</div>
