
  
    <ng-template #docSelect>
      <select class="form-control form-control-sm"  (change)="docTypeSelect($event.target.value)"  >
        <option [selected]="item.document_type === null" disabled >Document Type</option>
        <option *ngFor="let t of types" [value]="t.key" [selected]="t.key == item.document_type?.toLowerCase()">{{t.value}}</option>
      </select>
    </ng-template>

    <ng-template #billSelect>
      <ng-multiselect-dropdown
          [data]        ="itemList"
          [settings]    ="settings"
          [(ngModel)]   ="selectedList"
          [placeholder] ="(mode== EntryAssignType.BILL) ? 'Select Bill' : 'Select Container' "
          (onSelect)    ="onItemSelect($event)"
          (onSelectAll) ="onItemSelectAll($event)"
          (onDeSelect)  ="onItemDeselect($event)"
          (onDeSelectAll)="onItemDeselectAll($event)"
      >
      </ng-multiselect-dropdown>
    </ng-template>
    
    <ng-template #default>
      <div class="form-default">
        <div *ngTemplateOutlet="docSelect"></div>
        <div *ngIf="showSelect">
          <div *ngTemplateOutlet="billSelect"></div>
        </div>
      </div>
     
    </ng-template>

    <ng-template #horizonalLayout>
      <div class="form-inline">
        <div *ngTemplateOutlet="docSelect"></div>
        <div *ngIf="showSelect" style="width: 180px; margin-left: 6px;">
          <div *ngTemplateOutlet="billSelect"></div>
        </div>
      </div>
    </ng-template>
    
    <div>
      <ng-container *ngIf="horizontal; then horizonalLayout else default"></ng-container>

    </div>
  