import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {MailItemDivisionSelect, MailItemSorting, MailItemStatusSelect} from './mailbox-component/mailbox.component';
import {CurrentEntryDocs, EntryAssignmentHistory, FreshlookEntrySearch} from './freshlook-data/freshlook-data.component';
import {OpenkmDataComponent, OpenkmModal} from './openkm-data/openkm-data.component';
import {RouterModule, Routes} from "@angular/router";
import {NgbModule, NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {NavComponent} from './nav/nav.component';
import {MailboxDataService} from "./mailbox-component/mailbox.service";
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule} from "@angular/forms";
import {FreshlookDataService} from "./freshlook-data/freshlook-data.service";
import {OpenkmDataService} from "./openkm-data/openkm-data.service";
import {
  DocumentTypeSelect,
  EntryAssignmentList, EntryAssignmentRemovalList, PageAssignedEntries,
  PageCheckbox,
  PageSplitComponent
} from "./pagesplit-component/pagesplit.component";
import {DndModule} from "ng2-dnd";
import {GoldenLayoutModule, DEFAULT_LOCAL_STORAGE_STATE_STORE_PROVIDER} from '@embedded-enterprises/ng6-golden-layout';
import {CommonModule} from "@angular/common";
import {HomeLayoutComponent} from './home-layout/home-layout.component';
import {Layout} from "./home-layout/layout";
import * as $ from 'jquery';
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {EntryAssignContextComponent} from './entry-assign-context/entry-assign-context.component';
import {MailboxFilter} from "./mailbox-component/list/mailbox.filter";
import {HttpClientModule} from '@angular/common/http';
import {TestLayout} from './test-layout/test.layout';
import {MailboxList} from './mailbox-component/list/mailbox.list';
import {PageImgSm, PageSplitItem} from './pagesplit-component/pagesplit.item';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService
} from '@azure/msal-angular';
import {environment} from '../environments/environment';
import {environment as envTest} from "../environments/environment.test"
import {GroupGuard} from './group.service';
import {MailbodyViewer} from './mailbox-component/mailbox.mailbody';
import {ExcelViewModal} from "./pagesplit-component/excel.view";
import {AuditLogViewer} from "./mailbox-component/mailbox.auditlog";
import {NgxDocViewerModule} from "ngx-doc-viewer";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";


const routes : Routes = [
  { path:'', component:HomeLayoutComponent, pathMatch:'full', canActivate: [MsalGuard]},
  { path:'home', component: HomeLayoutComponent, pathMatch:'full'},
  { path:'testing', component: TestLayout}
];


window['$'] = $;

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'c5a8063a-320b-490f-b631-31ab4fb6b148',
      authority: 'https://login.microsoftonline.com/07ccc854-4ebb-484f-a136-cdd33f2ad837',
      redirectUri: environment.redirect,
      navigateToLoginRequestUrl: true
      //postLogoutRedirectUri: environment.redirect
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {

  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(environment.host, ['api://02c62f0c-3ef0-45ba-9b6e-6ef1e1eaaf96/docsort_user']);
  protectedResourceMap.set(envTest.host, ['api://02c62f0c-3ef0-45ba-9b6e-6ef1e1eaaf96/docsort_user']);
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['user.read']
    },
    //loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    //MailboxListComponent,
    MailItemStatusSelect,
    MailItemDivisionSelect,
    MailItemSorting,
    MailboxFilter,
    FreshlookEntrySearch,
    CurrentEntryDocs,
    EntryAssignmentHistory,
    OpenkmDataComponent,
    NavComponent,
    OpenkmModal,
    ExcelViewModal,
    PageSplitComponent,
    PageSplitItem,
    PageCheckbox,
    DocumentTypeSelect,
    HomeLayoutComponent,
    MailbodyViewer,
    AuditLogViewer,
    EntryAssignContextComponent,
    TestLayout,
    MailboxList, //testing
    EntryAssignmentList,
    PageAssignedEntries,
    EntryAssignmentRemovalList,
    PageImgSm
  ],
  imports: [
    RouterModule.forRoot(routes),
    NgbModule,
    NgbPaginationModule,
    DndModule.forRoot(),
    GoldenLayoutModule.forRoot(Layout.getLayout()),
    NgMultiSelectDropDownModule.forRoot(),
    NgxDocViewerModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    MailboxDataService,
    FreshlookDataService,
    OpenkmDataService,
    GroupGuard,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DEFAULT_LOCAL_STORAGE_STATE_STORE_PROVIDER,
    { provide: HTTP_INTERCEPTORS,  useClass: MsalInterceptor,  multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory},
    { provide: MSAL_GUARD_CONFIG, useValue: {interactionType: InteractionType.Popup} as MsalGuardConfiguration},
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory},
  ],
  entryComponents:[
    OpenkmModal,
    ExcelViewModal,
    //MailboxListComponent,
    FreshlookEntrySearch,
    EntryAssignmentHistory,
    MailbodyViewer,
    AuditLogViewer,
    MailboxList,
    PageSplitComponent

  ],
  bootstrap: [AppComponent,NavComponent]
})
export class AppModule { }
