
        <div class="pg-item-entires">
            <ul>
                <li *ngFor="let p of ea">
                    <span class="badge badge-warning entry-assign">
                        <span>{{p.entry.broker_number}}</span>
                        <a href="#" (click)="itemRemove(p,$event)" class="entry-remove badge"><i class="fa fa-remove"></i></a>
                    </span>
                </li>
            </ul>
        </div>
    