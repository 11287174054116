
/**
 * token timeout workaround
 * source: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/759
 */
export function checkToken(errCallback?) {

    const timestamp = Math.floor((new Date()).getTime() / 1000);

    let token = null;

    for(const key of Object.keys(localStorage)) {

        if(key.includes('"authority":')) {

            const val: any = JSON.parse(localStorage.getItem(key)!);

            if(val.expiresIn) {
                // We have a (possibly expired) token

                if(val.expiresIn > timestamp && val.idToken === val.accessToken) {
                    // Found the correct token
                    token = val.idToken
                }
                else{
                    // Clear old data
                    localStorage.removeItem(key)
                }
            }
        }
    }

    if(token){return token;}

    console.log('No valid token found');

    errCallback();

}
