/**
 *
 * @param image
 * @param callback
 */
export function imageFromBlob(image: Blob, callback){
    let reader = new FileReader();

    reader.addEventListener("load", () => callback(reader.result), false);

    if(image){reader.readAsDataURL(image);}
}
