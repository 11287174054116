
    <div id="mailbody" class="">
      <div class="" *ngIf="item != null">
          <table class="table table-sm table-bordered">
              <tr>
                  <th class="table-dark">To:</th>
                  <td colspan="3">
                      <div class="" style="max-height: 75px; overflow-y: scroll">
                          <table class="table table-sm m-0">
                              <tr *ngFor="let row of listTo()">
                                  <td *ngFor="let i of row">{{i}}</td>
                              </tr>
                          </table>
                      </div>

                  </td>
              </tr>
              <tr>
                  <th class="table-dark">Cc:</th>
                  <td colspan="3">
                      <div class="" style="max-height: 75px; overflow-y: scroll">
                          <table class="table table-sm m-0">
                              <tr *ngFor="let row of listCc()">
                                  <td *ngFor="let i of row">{{i}}</td>
                              </tr>
                          </table>
                      </div>

                  </td>
              </tr>
              <tr>
                  <th class="table-dark">From:</th>
                  <td>{{item.mail_from}}</td>
                  <th class="table-dark">Date:</th>
                  <td>{{formatDatetime(item.mail_date+" "+item.mail_time)}}</td>
              </tr>
              <tr>
                  <th class="table-dark">Subject:</th>
                  <td colspan="3">
                      <span><strong>{{item.mail_subject}}</strong></span>
                  </td>
              </tr>
          </table>
          
      </div>
      <!--<iframe id="mail-view" style="width: 100%; height: 100%; border: none"></iframe> -->
        <div [innerHTML]="item?.mail_body" style="max-height:350px; overflow-y: scroll"></div>
    </div>
    