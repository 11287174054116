<div>
  <ul class="selected-entries list-inline">
    <li class="list-inline-item" *ngFor="let item of selected">
      <div class="badge badge-success">
        <span class="entry-item" title="Bol: {{item.bol}} Container: {{item.container}}">{{item.broker_number}}</span>
        <a href="#" class="entry-remove badge" (click)="itemSelect(false,item)"><i class="fa fa-remove"></i></a>
      </div>
    </li>
  </ul>
</div>


<div style="overflow-y:scroll;height:100%">

  <div class="container-fluid">

    <div class="row">

      <div class="col-2 pr-0">

        <div class="input-group mb-0;" style="width:80px;">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input type="checkbox" aria-label="Use Archive" title="Enable archive search"
                     [(ngModel)]="useArchive">
            </div>
          </div>
          <input type="text" class="form-control form-control-sm" title="Archive days" size="4" style="width: 20px !important;" aria-label="Archive"
                 [disabled]="!useArchive" [(ngModel)]="archiveDays" (ngModelChange)="checkArchiveDays()">
        </div>

      </div>


      <div class="col-10 pl-0">

        <div id="doctype-legend">
          <ul class="list-inline">
            <li class="list-inline-item">
              <span class="badge badge-arrival"><i class="fa fa-check-circle"></i></span>Arrival
            </li>
            <li class="list-inline-item">
              <span class="badge badge-bl"><i class="fa fa-check-circle"></i></span>BL
            </li>
            <li class="list-inline-item">
              <span class="badge badge-phyto"><i class="fa fa-check-circle"></i></span>Phyto
            </li>
            <li class="list-inline-item">
              <span class="badge badge-invoice"><i class="fa fa-check-circle"></i></span>Commercial Inv
            </li>
            <li class="list-inline-item">
              <span class="badge badge-isf"><i class="fa fa-check-circle"></i></span>ISF
            </li>
            <li class="list-inline-item">
              <span class="badge badge-usda"><i class="fa fa-check-circle"></i></span>Cold Treatment
            </li>
            <li class="list-inline-item">
              <span class="badge badge-203"><i class="fa fa-check-circle"></i></span>203
            </li>
            <li class="list-inline-item">
              <span class="badge badge-origin"><i class="fa fa-check-circle"></i></span>Cert of Origin
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>


  <table id="entry-list" class="table table-condensed table-striped table-bordered hidden">
    <thead class="">
    <tr>
      <th>Save</th>
      <th>Broker | Entry </th>
      <th colspan="2">BL | Container</th>
      <th>Shipper | Cust</th>
      <th>Vessel | ETA</th>
    </tr>
    <tr>
      <td><button class="btn btn-sm btn-danger" title="Clear Filter" (click)="clearParams()"><i class="fa fa-remove"></i></button></td>
      <td><input type="text" class="form-control form-control-sm" [(ngModel)]="comb1" (keypress)="keyPress($event)" placeholder="Broker,Entry"></td>
      <td colspan="2"><input type="text" class="form-control form-control-sm" [(ngModel)]="comb2" (keypress)="keyPress($event)" placeholder="BL,Container"></td>
      <td><input type="text" class="form-control form-control-sm" [(ngModel)]="comb4" (keypress)="keyPress($event)" placeholder="Shipper, Cust"></td>
      <td><input type="text" class="form-control form-control-sm" [(ngModel)]="comb3" (keypress)="keyPress($event)" placeholder="Vessel, ETA"></td>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let e of entries">
        <td>
          <button *ngIf="e.broker_number !== null" (click)="entrySelected(e,'entry')" class="btn btn-sm btn-primary" title="Assign to:"><i class="fa fa-arrow-circle-left "></i></button>
        </td>
        <td>
          <a href="#" (click)="brokerClick($event,e)">{{e.broker_number}}</a><br/>
          <span class="badge badge-primary">{{e.company}}</span>&nbsp;
          <span class="badge badge-info">{{e.division}}</span> &nbsp; <span class="badge badge-danger" *ngIf="e.ff" title="Freight Entry">F</span> &nbsp;
          <span class="badge badge-secondary">{{e.entry_number}}</span><br/>
          <current-docs [docGroup]="e.doc_group"></current-docs>
        </td>
        <td nowrap>
          <ul class="bol-list">
            <li *ngFor="let b of e.bill_group;">
              <div class="badge badge-secondary">{{(b.type=='master')? "M" : "H"}}</div><small>{{b.value}}</small>
            </li>
          </ul>
        </td>
        <td>
          <ul class="bol-list">
            <li *ngFor="let c of e.container_group;"><small>{{c.value}}</small></li>
          </ul>
        </td>
        <td>
          <span class="badge badge-light" title="Customer: {{e.cust}}">C: {{e.cust | slice:0:20}}</span><br/>
          <span class="badge badge-light" title="Shipper: {{e.shipper}}">S: {{e.shipper | slice:0:20}}</span>
        </td>
        <td>
          <small>{{e.vessel}}</small>
          <br/>
          <span class="badge badge-secondary">ETA: {{e.eta2}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
