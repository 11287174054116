import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Group} from "@microsoft/microsoft-graph-types";
import {MsalService} from "@azure/msal-angular";
import {imageFromBlob} from "./img.blob";


@Injectable({providedIn:"root"})
export class GraphService {

    private groups:Array<string> = [];

    /**
     *
     * @param http
     * @param adal
     */
    constructor(private http:HttpClient, private msalService:MsalService) {

    }


    /**
     *
     * @param cb
     */
    public getPhoto(cb?){

        if(localStorage.getItem("docsort.azure.photo")){

            cb(localStorage.getItem("docsort.azure.photo"));

            return;
        }

        /**
         *
         * Convert image blob data to base64 and return through callback
         *
         * @param image
         * @param cb
         */
        this.http.get("https://graph.microsoft.com/v1.0/me/photo/$value",{responseType:'blob'}).toPromise().then((r:Blob) => {

            imageFromBlob(r, (img) => {
                localStorage.setItem("docsort.azure.photo",img);
                cb(img);
            });
        })

        .catch((err: HttpErrorResponse) => console.log(err.message));
    }



    /**
     *
     * @param role
     */
    hasRole(role:string):boolean{
        let appRoles:Array<string> =  this.msalService.instance.getActiveAccount().idTokenClaims['roles'];

        if(appRoles){
            return appRoles.filter((r) => r == role).length > 0;
        }

        return false;
    }


    /**
     *
     * @param roles
     */
    hasAnyRole(roles:Array<string>):boolean{

        let appRoles:Array<string> =  this.msalService.instance.getActiveAccount().idTokenClaims['roles'];

        if(appRoles){
            let i = appRoles.filter((role) => roles.includes(role))

            return i.length > 0;
        }

        return false;
    }


    /**
     *
     * @param groups
     */
    hasOneOfGroups(groups:Array<string>):boolean{

        let azGroups:string[] = JSON.parse(localStorage.getItem("docsort.azure.groups"));

        let intersection = azGroups.filter((g) => groups.includes(g));

        //console.log("group:: ",intersection);

        return (intersection.length > 0);
    }


    async getGroups() {

        if(localStorage.getItem("docsort.azure.groups")){
            console.log("return cached groups");

            return JSON.parse(localStorage.getItem("docsort.azure.groups"));
        }


        let data = await this.http.get("https://graph.microsoft.com/v1.0/me/memberOf")
            .toPromise()
            .then((res:{"@odata.type":string, value:Array<Group>}) =>
                res.value
                    .filter((i) => i.displayName !== null)
                    .map((i) => i.displayName));


        if(data.length == 0){
            data.push("Guest");
            localStorage.setItem("docsort.azure.groups",JSON.stringify(data));
        }

        localStorage.setItem("docsort.azure.groups",JSON.stringify(data));

        return data;
    }


    clearCache(){
        localStorage.removeItem("docsort.azure.groups");
        localStorage.removeItem("docsort.azure.photo");
    }

}
