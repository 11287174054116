import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import * as GoldenLayout from 'golden-layout';
import {checkToken} from '../auth.token';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular';
import {GraphService} from '../graph.service';
import {filter, takeUntil} from "rxjs/operators";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest
} from "@azure/msal-browser";
import {Subject} from "rxjs";


/**
 * Main component container for Golden Layout
 */
@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent  implements OnInit{

  @ViewChild("layout", { static: true }) ly : GoldenLayout;

  isIframe = false;
  loggedIn = false;

  userAccount;
  photo;

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();


	/**
	 *
	 * @param msalGuardConfig
	 * @param msalBroadcastService
	 * @param authService
	 * @param graph
	 */
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private msalBroadcastService: MsalBroadcastService,
              private authService: MsalService,private graph: GraphService) {}



  ngOnInit(){

    this.isIframe = window !== window.parent && !window.opener;

    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
        .pipe( filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED))
        .subscribe((result: EventMessage) => {
          if(this.authService.instance.getAllAccounts().length === 0) {
            window.location.pathname = "/";
          }
          else{ this.setLoginDisplay(); }
        });

    this.msalBroadcastService.msalSubject$
        .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
        .subscribe((result: EventMessage) => {
          //this.loggedIn = !!this.authService.instance.getActiveAccount();
          this.loggedIn = true;
        })

    this.msalBroadcastService.inProgress$
        .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            takeUntil(this._destroying$)
        )
        .subscribe(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        })


    /**
     * Keep login active
     */
    window.setInterval(() => {
      //this.keepAlive()
    },(10 * 60000))


    this.checkAndSetActiveAccount();

  }


  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }


  /**
   * If no active account set but there are accounts signed in, sets first account to active account
   * To use active account set here, subscribe to inProgress$ first in your component
   * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
   */
  checkAndSetActiveAccount() {

    this.userAccount = this.authService.instance.getActiveAccount();

    //console.log(this.userAccount.idTokenClaims['roles']);

    this.loggedIn = !!this.userAccount;

    if(this.graph.hasRole("docsort.users.admins")){console.log("is admin")}

    this.graph.getPhoto((photo) => this.photo = photo);

    if (!this.userAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }


  loginRedirect() {

    if(this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    }
    else { this.authService.loginRedirect(); }
  }


  loginPopup() {

    if(this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest).subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    }
    else{
      this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    }
  }


  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });

      this.graph.clearCache();

    } else {
      this.authService.logoutRedirect();
    }
  }


  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }



  private keepAlive(){

    checkToken(() => {
      this.authService.loginPopup().subscribe((res) => {
        console.log("loggin back in");
      });
    });
  }



}
