
        <div style="max-height: 400px; overflow-x: scroll">
			<div>
                <span>{{item?.id}}</span>
				<span (click)="getData()" class="pull-right"><i class="fa fa-recycle"></i></span>
			</div>
			
            <table class="table table-sm table-bordered table-striped  table-warning small" *ngIf="auditData.length > 0">
                <thead>
                <tr>
                    <th>RefId</th>
                    <th>Action</th>
                    <th>Old</th>
                    <th>Value</th>
                    <th>User</th>
                    <th>Datetime</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let d of auditData;">
                    <td>{{d.refId}}</td>
                    <td>{{d.action}}</td>
                    <td>{{d.oldValue}}</td>
                    <td>{{d.value}}</td>
                    <td>{{d.user}}</td>
                    <td>{{d.dateTime | date:'medium'}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    