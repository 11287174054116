<main-nav
        (loginEvent)="loginPopup()"
        (logoutEvent)="logout()"
        [userInfo]="userAccount"
        [userPhoto]="photo"
        [isLoggedIn]="loggedIn"
></main-nav>

<div class="row">
  <div class="col-12 no-float">
    <div id="gl-wrap" *ngIf="loggedIn">
      <golden-layout-root #layout></golden-layout-root>
    </div>
  </div>
</div>
