import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FilterFieldItem, MailboxDataService} from "../mailbox.service";
import { timer } from 'rxjs';
import {GraphService} from "../../graph.service";


@Component({
  selector: 'mailbox-filter',
  templateUrl: 'mailbox.filter.html',
  styleUrls: ['../mailbox-component.component.css']
})
export class MailboxFilter implements OnInit{
  
  
  private _folderCounts:Array<{mail_folder:string,count:number}> = [];
  
  private _domainCounts:Array<{domain:string,count:number}> = [];
  
  private _dateCounts:Array<{mail_date:string,count:number}> = [];

  private divisions:Array<{id:string,label:string}> = [
      {id:"DocsJKFE",               label: "JKF East Perishable"},
      {id:"Docs721",                label: "JKF East Dry (721)"},
      {id:"DocsJKFWest",            label: "JKF West"},
      {id:"DocsWestArrivals",       label: "West Arrivals"},
      {id:"DocsWestChile",          label: "West Chile"},
      {id:"DocsWestPeru",           label: "West Peru"},
      {id:"DocsWestAir",            label: "West Air"},
      {id:"DocsWestTropical",       label: "West Tropical"},
      {id:"DocsWestNZAustralia",    label: "West NZ/Australia"},
      {id:"DocsWestItaly",          label: "West Italy"},
      {id:"DocsWestHouston",        label: "West Houston"},
      {id:"DocsWestNonPerishable",  label: "West Non-Perishable"},
      {id:"DocsWestExams",          label: "West Exams"},
  ];


  @Input()
  private filterFields:Array<FilterFieldItem> = [];

  @Output()
  filterFieldsChange:EventEmitter<Array<FilterFieldItem>> = new EventEmitter();
  
  @Input()
  optionsOpen:boolean = false;
  
  @Input()
  status:string;



  /**
   *
   * @param mb
   * @param gr
   */
  constructor(private mb:MailboxDataService, private gr:GraphService) {

  }
  
  
  
  ngOnInit(): void {

      /**
       * Preset doc groups
       */

      if(localStorage.getItem("ds.filter.state") === null){
          if(this.gr.hasRole("docsort.users.721")){
              this.filterFields.push({field:"division", value:"Docs721"});
          }

          if(this.gr.hasRole("docsort.users.jkfe")){
              this.filterFields.push({field:"division", value:"DocsJKFE"});
          }
      }

      timer(0,60000*5).subscribe((r) => this.getData());
    
  }
  
  
  private getData(){
      this.mb.getMailFolderGroupCount(this.status.toUpperCase()).then((res) => {
          this._folderCounts = res;
      });

      this.mb.getDomainGroupCount(this.status.toUpperCase()).then((res) => {
          this._domainCounts = res;
      });

      this.mb.getMailDateGroupCount(this.status.toUpperCase()).then((res) => {
          this._dateCounts = res;
      });
  }


  /**
   *
   * @param status
   */
  refresh(status:string){
    this.status = status.toUpperCase();
    this.getData();
  }
  
  
  /**
   *
   */
  getFolderCounts(): Array<{ mail_folder: string; count: number }> {
    return this._folderCounts;
  }
  
  
  getDomainCounts(): Array<{ domain: string; count: number }> {
    return this._domainCounts;
  }
  
  
  getDateCounts(): Array<{ mail_date: string; count: number }> {

    return this._dateCounts.sort((a,b) =>{
      if(a.mail_date > b.mail_date){return -1;}
      if(a.mail_date < b.mail_date){return 1;}
      return 0;
    });
  }


  getDivisions(){
    return this.divisions;
  }
  
  
  /**
   * 
   * @param item 
   */
  addFilterField(item:FilterFieldItem){
    let ex = this.filterFields.find((field) => JSON.stringify(field) == JSON.stringify(item));

    if(ex){return false;}

    this.filterFields.push(item);

    this.filterFieldsChange.emit(this.filterFields);
  }


  /**
   * 
   * @param item 
   */
  removeFilterField(item:FilterFieldItem){
    this.filterFields = this.filterFields.filter((field) => JSON.stringify(field) != JSON.stringify(item));

    this.filterFieldsChange.emit(this.filterFields);
  }


  /**
   * 
   * @param field 
   * @param value 
   */
  filterExists(field:string, value:string):boolean{
    let filter:FilterFieldItem = {field:field, value:value};

    return (this.filterFields.find((f) => JSON.stringify(filter) == JSON.stringify(f))) ? true : false;
  }

  

  /**
   * 
   * @param e 
   * @param field 
   * @param value 
   */
  updateFilter(e:any,field:string, value:string){
    if(e.target.checked){
      this.addFilterField({field:field, value:value});
      return true;
    }

    this.removeFilterField({field:field, value:value});
  }



  clear(){
    this.filterFields = [];
  }
  
}
