import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {GraphService} from "./graph.service";
//import {NotifierService} from "angular-notifier";


@Injectable({providedIn:"root"})
export class GroupGuard implements CanActivate{

    //private readonly notifier: NotifierService;

    /**
     *
     * @param router
     * @param graph
     * @param notifierService
     */
    constructor(private router: Router, private graph:GraphService) {
        //this.notifier = notifierService;
    }


    /**
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        console.log("Has Group: ", this.graph.hasRole(route.data.role));

        if(this.graph.hasAnyRole(route.data.role)){

            return true;
        }

        //this.notifier.notify("error", "You don't have access to this section");

        return false;
    }

}
