import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    Inject, AfterViewInit
}
    from '@angular/core';

import {
    CommitValidationResponse,
    EntryAssignment,
    EntryAssignmentBulkRemoveRequest,
    EntryAssignmentRequest,
    MailboxDataService,
    MailItem,
    PageCombineRequest,
    PageItem,
    PageUncombineRequest,
    SelectedMailItem
}
    from '../mailbox-component/mailbox.service';

import * as _ from 'lodash';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EntryAssignContextComponent, EntryAssignType} from '../entry-assign-context/entry-assign-context.component';
import {AssignmentContext} from '../entry-assign-context/assignment.context';
import {GoldenLayoutComponentState, GoldenLayoutContainer} from '@embedded-enterprises/ng6-golden-layout';
import * as GoldenLayout from 'golden-layout';
import {EntryResult} from '../freshlook-data/freshlook-data.service';
import {PageSplitItem} from './pagesplit.item';
import {DocType, docTypes} from '../mailbox-component/mailbox.model';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {isElementScrolledOutsideView} from "@angular/cdk/overlay/position/scroll-clip";


declare var $: any;

@Component({
    selector: 'pagesplit-view',
    templateUrl: 'pagesplit.view.html',
    styleUrls: ['./pagesplit.component.css']
})
export class PageSplitComponent implements OnInit {

    @Input()
    item: MailItem = null;

    selectedPage: PageItem = null;

    selectedMailItem:SelectedMailItem = null;

    selectedMailItemEntries:Array<EntryAssignment> = [];

    @Input()
    docTypes: Array<{ key: string, value: string }>;

    @Output()
    pageClickEvent: EventEmitter<PageItem> = new EventEmitter();

    @ViewChildren('pgitem')
    pageitems: QueryList<PageSplitItem>;

    pageIndex = [];

    @ViewChild('eacontext', { static: false }) eacontext : EntryAssignContextComponent;

    public thumbData:Array<{id:string,data:SafeResourceUrl}> = [];

    public chkAll: boolean = false;

    public chkAllRevised: boolean = false;

    public chkAllHidden: boolean = false;

    viewMode = 'pages';

    docData;

    docDataLoading:boolean = true;

    colSize = 2;


    constructor(private mb: MailboxDataService,
                private modal: NgbModal,
                private cd: ChangeDetectorRef,
                private sanitizer: DomSanitizer,
                @Inject(GoldenLayoutComponentState) public state: any,
                @Inject(GoldenLayoutContainer) private gcontainer: GoldenLayout.Container) {
    }


    ngOnInit() {

        this.docTypes = docTypes;

        this.gcontainer.layoutManager.eventHub.on('mailItemSelect', (selectedItem: SelectedMailItem) => {

            this.docDataLoading = true;

            this.viewMode = 'view';

            this.selectedMailItem = selectedItem;

            this.mb.getById(selectedItem.item.id, selectedItem.grouped).subscribe((i) => {

                this.mb.getThumbData(selectedItem.item.id, selectedItem.grouped).subscribe((imageData) => {

                    this.thumbData = imageData.map((img) =>
                        ({id: img.id, data: this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,'+img.data)}));

                    this.setPageData(i);

                    this.viewMode = 'pages';

                    /**
                     * Build page lookup for ui refreshing
                     */
                    this.pageitems.changes.subscribe((data) => {

                        this.pageIndex = [];

                        data.forEach((d) => this.pageIndex[d.item.id] = d.index);

                    });

                });
            });

        });


        this.gcontainer.layoutManager.eventHub.on('entrySelect', (ea: EntryAssignment) => {
            this.handleEntryItemAssignment(ea);
        });

        this.gcontainer.layoutManager.eventHub.on('itemCommit', (c:CommitValidationResponse) => {
            //this.clearDocView();
        });

        this.gcontainer.layoutManager.eventHub.on('statusChange', () => {
            //this.clearDocView();
        });
    }


    selectAllPages() {
        this.chkAll = !this.chkAll;

        this.pageitems.forEach((p) => p.item.selected = this.chkAll);
    }


    selectAllRevised() {
        this.chkAllRevised = !this.chkAllRevised;

        this.pageitems.forEach(pg => {
            this.mb.setPageRevised(pg.item.id, this.chkAllRevised).then(() => {
                pg.item.revised = this.chkAllRevised;
            });
        });

    }


    selectAllHidden(){
        this.chkAllHidden = !this.chkAllHidden;
        this.pageitems.forEach((pg) => {
            this.mb.setPageHidden(pg.item.id,this.chkAllHidden).then(() => {
                pg.item.hidden = this.chkAllHidden;
            })
        });
    }


    /**
     *
     * @param dir
     */
    pageZoom(dir){

        if(dir == "+"){
            if(this.colSize < 5){
                this.colSize+=1;
                return;
            }
        }

        if(this.colSize > 2){
            this.colSize -=1;
        }
    }


    /**
     *
     * @param {MailItem} item
     */
    setPageData(item: MailItem) {

        this.clearDocView();

        this.chkAll = false;
        this.chkAllRevised = false;
        this.item = item;

        this.viewMode = 'pages';

        /**
         * If there's only one page, auto select it
         */
        if (this.item.page_splits.length == 1) {
            this.chkAll = true;
            this.item.page_splits.forEach((p) => {
                p.selected = true;
            });
        }

        this.updateSelectedMailItemEntriesMenu();

    }


    updateSelectedMailItemEntriesMenu(){
        this.selectedMailItemEntries = this.item.page_splits
            .flatMap((p) => p.entry_assignment.map((ea)=> ea))
            .filter((val, index, self) => {
                return  self.findIndex((i) => i.entry.entry_id == val.entry.entry_id) == index;
            });
    }



    /**
     * Get the page item's pdf data and display in a iframe
     *
     * @param page
     */
    pageClick(page: PageItem) {

        this.viewMode = 'view';

        this.docDataLoading = true;

        this.selectedPage = page;

        this.pageClickEvent.emit(page);

        if(this.isPdf()) {

            /**
             * Get doc blob data
             */
            this.mb.getPageData(page.id).then((data:Blob) => {
                let blob: any = new Blob([(data)], { type: 'application/pdf' });
                let blobUrl= URL.createObjectURL(blob).concat("#view=FitH");
                this.docData = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
                this.docDataLoading = false;
            });

            return;
        }

        if(this.isExcel()){
            this.docDataLoading = false;
        }

    }



    pageForward(){

        let i = this.pageIndex[this.selectedPage.id];

        let p = this.getPageSplitItemByIndex(i+1);

        if(p !== null){
            this.pageClick(p.item);
            this.cd.detectChanges();
            this.eacontext.ngOnInit();
        }

    }


    pagePrevious(){
        let i = this.pageIndex[this.selectedPage.id];

        let p = this.getPageSplitItemByIndex(i-1);

        if(p !== null){
            this.pageClick(p.item);
            this.cd.detectChanges();
            this.eacontext.ngOnInit();
        }
    }



    /**
     *
     * @param id
     */
    excelUrl(page:PageItem){
        //return "https://testing.docsort.721logistics.com/io/excel/b0dd7b7c-dfc3-44b3-85f8-cf2d585ea916";

        return this.mb.getExcelDataUrl(page.id +"."+ page.mime_type);
    }


    /**
     *
     */
    isExcel():boolean{
        return ["doc","docx","xls","xlsx","csv"].includes(this.selectedPage.mime_type.toLowerCase())? true: false;
    }


    /**
     *
     */
    isPdf():boolean{
        return (["pdf"].includes(this.selectedPage.mime_type.toLowerCase()) ) ? true : false;
    }



    /**
     *
     * @param id
     */
    thumb(page:PageItem){

        let img =  this.thumbData.find((d) => d.id == page.id);

        if(img){ return img.data;}

        return null;
    }


    /**
     *
     */
    closeView() {
        this.docDataLoading = true;
        this.docData = null;
        this.viewMode = 'pages';
        this.selectedPage = null;
    }


    /**
     *
     */
    clearDocView() {
        this.docDataLoading = true;
        this.docData = null;
        this.item = null;
        this.selectedPage = null;
    }



    /**
     * Entry assignment for non-pdf original doc
     *
     * @param {EntryAssignment} ea
     */
    entryItemAssign(ea: EntryAssignment) {
        /**
         * Make sure we're not adding a duplicate entry assignment
         */
        const e: EntryAssignment = this.item.entry_assignment.find(a => ea.entry.entry_id == a.entry.entry_id);

        if(e) { return false; }

        const req: EntryAssignmentRequest = {id: this.item.id, assignment: ea};

        this.mb.assignEntry(req).then((r) => {

        });

    }



    /**
     * Entry assignment for pdf at page level
     * Assigns in multi-page view or single page full view
     *
     * @param {EntryAssignment} ea
     */
    handleEntryItemAssignment(ea: EntryAssignment){

        if(this.selectedPage !== null){

            this.assignToPage(this.selectedPage,ea);

            return true;
        }

        this.pageitems
            .filter((p) => p.item.selected == true)
            .forEach((p) => this.assignToPage(p.item,ea));

    }


    /**
     *
     * @param ea
     */
    entryAssignmentChange(ea:Array<EntryAssignment>){

        //this.selectedPage.entry_assignment = ea;

        this.mb.updatePageItemAssignment(this.selectedPage.id,ea)
            .then((r) => this.refreshPage(this.selectedPage));
    }


    /**
     *
     * @param page
     */
    getPageSplitItem(page:PageItem):PageSplitItem{
       return this.pageitems.find((p) => p.item.id === page.id);
    }


    /**
     *
     * @param index
     */
    getPageSplitItemByIndex(index:Number):PageSplitItem{
        return this.pageitems.find((p) => p.index === index) || null;
    }


    /**
     *
     * @param pageItem
     * @param ea
     */
    assignToPage(pageItem:PageItem, ea:EntryAssignment){

        if(!this.selectedMailItemEntries.find((e) => e.entry.entry_id == ea.entry.entry_id)){
            this.selectedMailItemEntries.push(ea);
        }

        const req: EntryAssignmentRequest = {id: pageItem.id, assignment: ea};

        this.mb.assignEntryToPage(req).then((page) => {

            let p = this.getPageSplitItem(page);

            /**
             * making sure the ui refreshes
             */
            if(this.selectedPage !== null){
                this.selectedPage = page;
            }

            p.item = page;

            p.item.selected = true;

            this.refreshPage(page);
        });
    }


    /**
     *
     * @param item
     * @param e
     * @param ev
     */
    public entryItemRemove(item: MailItem, e: EntryResult, ev?: Event) {

        item.entry_assignment = item.entry_assignment.filter((i) => i.entry.entry_id != e.entry_id);

        this.mb.removeEntry({id: item.id, entry: e}).then(r => {

        });
    }


    /**
     *
     * @param e
     */
    public entryPageRemove(e:EntryAssignment){
        this.updateSelectedMailItemEntriesMenu();
    }


    /**
     *
     * @param e
     */
    public entryPageItemRemove(e: EntryResult, ev?: Event) {

        this.selectedPage.entry_assignment = this.selectedPage.entry_assignment.filter((i) => i.entry.entry_id != e.entry_id);

        this.mb.removeEntryFromPage({id: this.selectedPage.id, entry: e}).then(() => {
            this.refreshPage(this.selectedPage);
        });
    }


    /**
     *
     * @param e
     * @param ev
     */
    public entryPageItemRemoveAll(e: EntryAssignment, ev?: Event){

        this.pageitems.forEach((p) => p.entryItemRemove(e));

        this.updateSelectedMailItemEntriesMenu();
    }



    /**
     *
     * @param e
     */
    pageDocType(e: { item: PageItem, type: DocType }) {

        /**
         * Remove any previously selected items
         */
        e.item.entry_assignment.forEach(ea => ea.data = null);

        this.mb.setPageDocType(e.item.id, e.type.key).then((r) => {

            /**
             * Set the doc type on the mail item for non-pdf
             */
            if(e.item.mime_type !== "pdf"){
                this.mb.setDocType(this.item.id,e.type.key)
            }


            /**
             * Set this page to hidden depeding on the doc type
             */
            this.mb.setPageHidden(e.item.id, e.type.hidden).then((r) => {
                this.item.hidden = e.type.hidden;
            });



            //this.setSingleSelection();

            this.refreshPage(e.item);

            let p = this.getPageSplitItem(e.item);
            p.eacontext.ngOnInit();

        });

    }


    /**
     *
     * Loop through each page item's entry assignments and check for single container/bill per entry
     * to be automatically checked off
     *
     */
    setSingleSelection() {

        this.pageitems
            .forEach((pg) => {

                let mode: EntryAssignType = EntryAssignType.mode(pg.item.document_type);

                if (mode == null){ return;}

                pg.item.entry_assignment.forEach((ea) => {

                    let context = (mode == EntryAssignType.BILL) ? AssignmentContext.bills(ea) : AssignmentContext.containers(ea);
                    ea.context = (mode == EntryAssignType.BILL) ? 'bill' : 'container';

                    if (context.length == 1) {

                        ea.data = ea.data || [];

                        /**
                         * Make sure we don't add a duplicate selection
                         */
                        let ex = ea.data.find(e => e.ref_id == context[0].ref_id);

                        if (!ex) {

                            ea.data = ea.data.concat(context);


                            this.mb.updatePageItemAssignment(pg.item.id, pg.item.entry_assignment).then((r) => {
                                pg.ngOnInit();
                                pg.eacontext.ngOnInit();
                            });
                        }

                    }

                });

            });

        this.cd.detectChanges();
        this.eacontext.ngOnInit();

    }


    /**
     *
     * @param page
     * @param cb
     */
    refreshPage(page:PageItem, cb?:(p:PageItem) => void){

        let mode: EntryAssignType = EntryAssignType.mode(page.document_type);

        if (mode == null){ return;}

        let p = this.getPageSplitItem(page);

        page.entry_assignment.forEach((ea) => {

            let context = (mode == EntryAssignType.BILL) ? AssignmentContext.bills(ea) : AssignmentContext.containers(ea);

            ea.context = (mode == EntryAssignType.BILL) ? 'bill' : 'container';

            if (context.length == 1) {

                ea.data = ea.data || [];

                /**
                 * Make sure we don't add a duplicate selection
                 */
                let ex = ea.data.find(e => e.ref_id == context[0].ref_id);

                if (!ex) { ea.data = ea.data.concat(context);

                    this.mb.updatePageItemAssignment(page.id, page.entry_assignment).then((r) => {
                        p.ngOnInit();
                        p.eacontext.ngOnInit();
                    });
                }

            }
        });

        //p.item.entry_assignment = page.entry_assignment;

        this.cd.detectChanges();
        p.eacontext.ngOnInit();


        if(this.eacontext){
            this.eacontext.ngOnInit();
        }

        if(cb){ cb(page);}

    }





    /**
     *
     * @param {{page_id: string; item: PageItem}} ev
     */
    uncombineEvent(ev: { page_id: string, item: PageItem }) {

        let req: PageUncombineRequest = {
            page_id: ev.page_id,
            remove_id: ev.item.id
        };

        /**
         * place the uncombined page next to the parent it was removed from
         */
        let idx = this.item.page_splits.map(p => p.id).indexOf(ev.page_id) + 1;

        this.mb.uncombine(req).then((r) => this.item.page_splits.splice(idx,0,ev.item));
    }


    /**
     *
     * @param {{id: string; added: string}} ev
     */
    combineEvent(ev: { id: string, added: string }) {

        if (ev.id == ev.added) {
            return;
        }

        this.item.page_splits = this.item.page_splits
            .flatMap((p) => p)
            .filter((i) => i.id != ev.added);


        let req: PageCombineRequest = {
            page_id: ev.id,
            ref_id: ev.added
        };

        this.mb.combine(req);

    }


    pagesOrdered(){
        return this.item.page_splits.sort((a,b) => {
            if(a.index < b.index){return -1;}
            if(a.index > b.index){return 1;}
            return 0;
        })
    }

}


@Component({
    selector: 'doctype-select',
    template: `
        <select class="form-control form-control-sm" (change)="itemSelect($event)">
            <option selected="selected" disabled>Document Type</option>
            <option *ngFor="let t of docTypes;" value="{{t.key}}">{{t.value}}</option>
        </select>
    `
})
export class DocumentTypeSelect implements OnInit {

    @Input()
    name: string = '';

    //@Input()
    public selected: any;

    @Input()
    docTypes: Array<{ key: string, value: string }>;

    @Output()
    changeEvent: EventEmitter<object> = new EventEmitter();

    ngOnInit(): void {
    }

    itemSelect(ev) {
        //console.log("SELECT",ev);
    }

}


@Component({
    selector: 'page-check',
    template: `
        <div style="margin: 0 auto;">

            <div *ngIf="checked==true; then ch_on; else ch_off;"></div>

            <ng-template #ch_on>
                <a href="#" (click)="checkChange($event)" class="badge badge-primary page-cb">
                    <i class="fa fa-check-square"></i><span *ngIf="label != null">&nbsp;{{label}}</span>
                </a>
            </ng-template>

            <ng-template #ch_off>
                <a href="#" (click)="checkChange($event)" class="badge badge-secondary page-cb">
                    <i class="fa fa-square"></i><span *ngIf="label != null">&nbsp;{{label}}</span>
                </a>
            </ng-template>

        </div>
    `,
    styleUrls: ['./pagesplit.component.css']
})
export class PageCheckbox implements OnInit {

    @Input()
    checked: boolean;

    @Input()
    label: string = null;

    @Output()
    change: EventEmitter<{ checked: boolean }> = new EventEmitter();

    ngOnInit(): void {
    }


    checkChange(ev: Event) {

        ev.preventDefault();

        this.checked = !this.checked;

        this.change.emit({checked: this.checked});
    }


}



@Component({
    selector:'assigned-entries-removal',
    template:`
        <ng-template #eaContent>
            <ul class="list-group">
                <li class="list-group-item p-0" *ngFor="let e of ea">
                    <div class="badge badge-danger" style="width: 100%;" title="Remove {{e.entry.broker_number}} from all pages.">
                        <span>{{e.entry.broker_number}}</span>
                        <a href="#" (click)="entryRemove(e,$event)" class="entry-remove badge pull-right"><i class="fa fa-remove"></i></a>
                    </div>
                </li>
            </ul>
        </ng-template>

        <a class="btn btn-sm btn-outline-danger" placement="bottom" [ngbPopover]="eaContent" container="body" triggers="mouseenter" [autoClose]="false">
            <span>Entries</span> <span>({{ea.length}})</span> :
        </a>
    `,
    styleUrls: ['./pagesplit.component.css']
})
export class EntryAssignmentRemovalList{

    @Input() ea:Array<EntryAssignment>;

    @Output() eaChange:EventEmitter<Array<EntryAssignment>> = new EventEmitter<Array<EntryAssignment>>();

    @Output() removeItem: EventEmitter<EntryAssignment> = new EventEmitter<EntryAssignment>();

    entryRemove(e:EntryAssignment,ev:Event){
        ev.preventDefault();
        this.removeItem.emit(e);
    }
}





@Component({
    selector:'assigned-entries',
    template:`
        <ng-template #eaContent>
            <ul class="list-group">
                <li class="list-group-item p-0" *ngFor="let e of ea">
                    <div class="badge badge-warning">
                        <span>{{e.entry.broker_number}}</span>
                        <a href="#" (click)="entryRemove(e,$event)" class="entry-remove badge"><i class="fa fa-remove"></i></a>
                    </div>
                </li>
            </ul>
        </ng-template>

        <a class="btn btn-sm btn-outline-warning" placement="bottom" [ngbPopover]="eaContent" container="body" triggers="mouseenter" [autoClose]="false">
            <span>Entries</span> <span>({{ea.length}})</span> :
        </a>
    `,
    styleUrls: ['./pagesplit.component.css']
})
export class EntryAssignmentList{

    @Input() ea:Array<EntryAssignment>;

    @Output() removeItem: EventEmitter<EntryAssignment> = new EventEmitter<EntryAssignment>();

    entryRemove(e:EntryAssignment,ev:Event){
        ev.preventDefault();
        this.removeItem.emit(e);
    }
}


@Component({
    selector:'page-assigned-entries',
    template:`
        <div class="pg-item-entires">
            <ul>
                <li *ngFor="let p of ea">
                    <span class="badge badge-warning entry-assign">
                        <span>{{p.entry.broker_number}}</span>
                        <a href="#" (click)="itemRemove(p,$event)" class="entry-remove badge"><i class="fa fa-remove"></i></a>
                    </span>
                </li>
            </ul>
        </div>
    `,
    styleUrls: ['./pagesplit.component.css']
})
export class PageAssignedEntries{

    @Input() ea:Array<EntryAssignment>;

    @Output() removeItem: EventEmitter<EntryAssignment> = new EventEmitter<EntryAssignment>()

    itemRemove(e:EntryAssignment,ev:Event){
        ev.preventDefault();
        this.removeItem.emit(e);
    }
}
