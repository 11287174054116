import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'main-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  @Input()
  isLoggedIn = false;

  @Input()
  userInfo;

  @Input()
  userPhoto = null;

  hasPhoto:boolean = false;

  @Output()
  loginEvent:EventEmitter<any> = new EventEmitter<any>();

  @Output()
  logoutEvent:EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if(this.userPhoto !== undefined){
      this.hasPhoto = true;
    }
  }


  login(){
    this.loginEvent.emit({});
  }


  logout(){

    this.logoutEvent.emit({});

  }
  
  resetLayout(){
    localStorage.removeItem("$ng-golden-layout-state");
    window.location.reload();
  }

}
