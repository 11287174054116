import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {EntryAssignment, MailboxDataService, MailItem, PageItem} from '../mailbox-component/mailbox.service';
import {EntryAssignContextComponent, EntryAssignType} from '../entry-assign-context/entry-assign-context.component';
import {DocType, docTypes} from '../mailbox-component/mailbox.model';
import {imageFromBlob} from "../img.blob";
import {GoldenLayoutComponentState, GoldenLayoutContainer} from "@embedded-enterprises/ng6-golden-layout";
import * as GoldenLayout from 'golden-layout';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'page-item',
    templateUrl: 'pagesplit.item.html',
    styleUrls: ['./pagesplit.component.css']
})
export class PageSplitItem implements OnInit, AfterViewInit{

    @Input() item : PageItem;

    @Input() mailItem: MailItem;

    @Input() types:Array<{key:string,value:string}> = [];

    @Input() excel:boolean = false;

    @Output() addToEvent : EventEmitter<{id:string,added:string}> = new EventEmitter();

    @Output() pageTypeEvent : EventEmitter<{item:PageItem,type:DocType}> = new EventEmitter();

    @Output() pageUncombineEvent : EventEmitter<{page_id:string,item:PageItem}> = new EventEmitter();

    @Output() pageClickEvent : EventEmitter<PageItem> = new EventEmitter();

    @Output() excelEntryRemove: EventEmitter<EntryAssignment> = new EventEmitter<EntryAssignment>();

    @Output() pageEntryRemove: EventEmitter<EntryAssignment> = new EventEmitter<EntryAssignment>();

    @ViewChild('eacontext', { static: false }) eacontext : EntryAssignContextComponent;

    @Input() index:Number = 0;

    image:Blob;

    @Input() imageData = null;

    imageLoading:boolean = true;

    smImg:string;

    itemSelected:boolean = false;

    assignMode:number;

    EntryAssignType;


    constructor(private mb: MailboxDataService,
                private cd: ChangeDetectorRef,
                private sanitizer: DomSanitizer,
                @Inject(GoldenLayoutComponentState) public state: any,
                @Inject(GoldenLayoutContainer) private gcontainer: GoldenLayout.Container){

        this.EntryAssignType = EntryAssignType;

    }



    ngAfterViewInit(): void {}


    ngOnInit(): void {

        this.types = docTypes;

        this.assignMode = EntryAssignType.mode(this.item.document_type);

        this.gcontainer.layoutManager.eventHub.on('entrySelect', (ea: EntryAssignment) => {

        });

    }


    /**
     *
     * @todo pass event up to PageSplitComponent
     *
     * @param ea
     */
    entryAssignmentChange(ea:Array<EntryAssignment>){

        this.cd.detectChanges();
        this.mb.updatePageItemAssignment(this.item.id,this.item.entry_assignment).then((r)=>{

            //this.item.entry_assignment = ea;
        });
    }


    entryItemPageAssign(ea: EntryAssignment){

    }


    dragStart(){
        this.cd.markForCheck();
    }

    dragEnd(){
        this.cd.markForCheck();

    }

    allowDropItem(){
        return (item: PageItem) => item.combine_items.length == 0;
    }


    pageClick(ev){
        this.pageClickEvent.emit(this.item);
    }


    combinePageClick(p:PageItem){
        this.pageClickEvent.emit(p);
    }


    /**
     * @todo change to pass change event instead
     */
    revisedChange(){
        this.item.revised = !this.item.revised;

        //console.log("Revised Doc:: ",this.item.revised);

        this.mb.setPageRevised(this.item.id,this.item.revised);
    }


    /**
     *
     */
    hiddenChange(){
        this.item.hidden = !this.item.hidden;
        this.mb.setPageHidden(this.item.id,this.item.hidden);
    }


    /**
     *
     * @param p
     */
    trancateId(p:PageItem) {

        let id = p.id.split("-");

        return id[id.length-1];
    }




    /**
     *
     * @param id
     */
    getImgData(id:string){

        this.mb.getImgData(id).then((i:Blob) => {
            imageFromBlob(i,(image) => {
                this.image = image;
                this.imageLoading = false;
            });
        });
    }


    public isPdf():boolean{
        return (this.item.mime_type == "pdf")? true : false;
    }


    isExcel():boolean{
        return ["xls","xlsx","csv"].includes(this.item.mime_type.toLowerCase())? true: false;
    }


    /**
     * Return a formated ms protocol link to open directly in excel
     * @param id
     */
    excelUrl(page:PageItem){
        return this.sanitizer.bypassSecurityTrustUrl(this.mb.getExcelDataUrl(page.id)+"."+page.mime_type);

        //return this.sanitizer.bypassSecurityTrustUrl("ms-excel:ofe|u|" + this.mb.getExcelDataUrl(page.id)+"."+page.mime_type);
    }


    /**
     *
     * @param e
     */
    docTypeSelect(e:{item:PageItem, type:DocType}){

        this.item.document_type = e.type.key.toUpperCase();
        this.item.hidden        = e.type.hidden;

        this.eacontext.ngOnInit();

        this.pageTypeEvent.emit(e);

    }


    /**
     *
     * @param {PageItem} item
     */
    uncombine(item:PageItem){
        this.pageUncombineEvent.emit({
            page_id : this.item.id,
            item : item
        });

        this.item.combine_items = this.item.combine_items.filter((i)=>{return i.id != item.id})
    }


    /**
     *
     * @param e
     * @param ev
     */
    entryItemRemove(e:EntryAssignment,ev?:Event){

        //ev.preventDefault();

        let req = {id:this.item.id, entry:e.entry};

        this.mb.removeEntryFromPage(req).then((r) =>{
            this.item.entry_assignment = this.item.entry_assignment.filter((i) => JSON.stringify(i.entry) !=  JSON.stringify(e.entry));
            if(this.eacontext){
                this.eacontext.ngOnInit();
                this.pageEntryRemove.emit(e);
                //this.eacontext.setData(this.item.entry_assignment);
            }
        });
    }



    entryMailItemRemove(e:EntryAssignment){
        this.excelEntryRemove.emit(e);
    }


    /**
     *
     * @param ev
     */
    dropEvent(ev){

        /**
         * Prevent item being dropped into itself
         */
        if(this.item.id == ev.dragData.id){return;}

        this.item.combine_items = this.item.combine_items || [];

        this.item.combine_items.push(ev.dragData)

        this.addToEvent.emit({
            id: this.item.id,
            added: ev.dragData.id
        });
    }

}



@Component({
    selector:'page-img-sm',
    template:`        
        <div *ngIf="!imageLoading" >
            <img  [src]="image" alt="" height="60" width="40" placement="right" (click)="viewPage()" style="border: #a74b5a solid 1px;"/>
            <span class="fa fa-minus-circle page-uncombine" (click)="uncombine()"></span>
        </div>
    `,
    styleUrls: ['./pagesplit.component.css']
})
export class PageImgSm implements OnInit{

    @Input() page: PageItem = null;
    @Output() uncombineEvent:EventEmitter<PageItem> = new EventEmitter<PageItem>();
    @Output() viewEvent:EventEmitter<PageItem> = new EventEmitter<PageItem>();

    imageLoading = true;
    image;

    constructor(private mb:MailboxDataService) {

    }

    ngOnInit(): void {

        if(this.page == null){return;}

        this.mb.getImgData(this.page.id).then((i:Blob) => {
            imageFromBlob(i,(image) => {
                this.image = image;
                this.imageLoading = false;
            });
        });
    }


    viewPage(){
        this.viewEvent.emit(this.page);
    }

    uncombine(){
        this.uncombineEvent.emit(this.page);
    }


}
