
export type DocType = {value:string, key:string, hidden:boolean};

export const docTypes:Array<DocType> = [
    {value:"APHIS Permit",                  key:"aphis_permit",         hidden:false},
    {value:"Arrival Notice",                key:"arrival_notice",       hidden:false},
    {value:"Bill of Lading",                key:"bol",                  hidden:false},
    {value:"Certificate of Origin",         key:"cert_of_origin",       hidden:false},
    {value:"Chain of Custody - EAN",        key:"chain_of_custody",     hidden:false},
    {value:"Commercial Invoice",            key:"commercial_invoice",   hidden:false},
    {value:"USDA Cold Treatment",           key:"cold_treatment",       hidden:false},
    {value:"Delivery Order",                key:"delivery_order",       hidden:false},
    {value:"Demurrage Receipt",             key:"demurrage_receipt",    hidden:false},
    {value:"Exam Receipt",                  key:"exam_receipt",         hidden:false},
    {value:"PPQ-203",                       key:"form_203",             hidden:false},
    {value:"CBP Form 3499",                 key:"form_3499",            hidden:false},
    {value:"CBP Form 6043",                 key:"form_6043",            hidden:false},
    {value:"Generic/Unknown Type",          key:"generic",              hidden:false},
    {value:"Packing List",                  key:"packing_list",         hidden:false},
    {value:"Phytosanitary",                 key:"phyto",                hidden:false},
    //{value:"Receipt",                       key:"receipt",              hidden:false},
    {value:"Export Packing Docs",           key:"export_packing",       hidden:false},
    {value:"Organic Certificate",           key:"organic_cert",         hidden:false},
    {value:"Certificate of Analysis",       key:"cert_of_analysis",     hidden:false},
    {value:"ISF Template",                  key:"isf_template",         hidden:false},
    {value:"ISF Late Notice",               key:"isf_late_notice",      hidden:false},
    {value:"FDA Notice",                    key:"fda_notice",           hidden:false},
    {value:"Emergency Action Notification", key:"emergency_action_notification", hidden:false},
    {value:"Form 7512",                     key:"form_7512",            hidden:false},
    {value:"Cold Treatment Report",         key:"cold_treatment_report",hidden:false},
    {value:"Courier Slip",                  key:"courier_slip",         hidden:false},
    {value:"Booking Docs",                  key:"booking_docs",         hidden:true},

    {value:"Drayage Invoice",               key:"drayage_inv",          hidden:true},
    {value:"Proof of Delivery",             key:"proof_delivery",       hidden:false},
    {value:"TSCA Certificate",              key:"cert_tsca",            hidden:false},
    {value:"Shipping Advice",               key:"shipping_advice",      hidden:false},
    {value:"Purchase Order",                key:"po",                   hidden:false},
    {value:"Health Certificate",            key:"cert_health",          hidden:false},
    {value:"Freight Docs",                  key:"freight_generic",      hidden:true},
    {value:"Weight List",                   key:"weight_list",          hidden:false},
    {value:"Certificate for the Export of Foodstuffs", key:"cert_export_foodstuffs",    hidden:false},
    {value:"Declaration of Heat Treatment", key:"heat_treatment",       hidden:false},
    {value:"Inspection Certificate",        key:"cert_inspection",      hidden:false},
    {value:"Dangerous Goods",               key:"dangerous_goods",      hidden:false},
    {value:"Damage",                        key:"damage",               hidden:false},
    {value:"Release Order (Turnover)",      key:"release_order",        hidden:true},

    {value:"USDA AMS Inspection Certificate",key:"usda_ams_inspection_cert",hidden:false},
    {value:"Insurance Certificate",         key:"insurance_cert",        hidden:false},
    {value:"Treatment Certificate",         key:"treatment_cert",        hidden:false},
    {value:"FDA Grower List",               key:"fda_grower_list",       hidden:false},
    {value:"Arrival Notice (Line)",         key:"line_arrival_notice",   hidden:false},
    {value:"Pickup Number",                 key:"pickup_number",         hidden:false},

    {value:"Entry Cancellation Packet",     key:"entry_cancellation_packet", hidden:false},
    {value:"Cargo Manifest",                key:"cargo_manifest",       hidden:false},
    {value:"Chassis Receipt",               key:"chassis_receipt",      hidden:false},
    {value:"Per Diem Receipt",              key:"per_diem_receipt",     hidden:false},
    {value:"Stamped Phyto",                 key:"stamped_phyto",        hidden:false},
    {value:"Stamped Re-export Phyto",       key:"reexport_phyhto",      hidden:false},

    {value:"Cut Flower Certificate",       key:"cert_cut_flowers",      hidden:false},
    {value:"Certification/Exemption of Label/Bottle Approvals",       key:"cola",      hidden:false},

    {value:"Mill Test Certificate",         key:"cert_mill_test",      hidden:false},
    {value:"Debit Note",                    key:"debit_note",           hidden:false},

    {value:"Exam Receipt Xray",             key:"exam_receipt_xray",    hidden:false},
    {value:"Exam Receipt - AQI",            key:"exam_receipt_aqi",     hidden:false},
    {value:"Exam Receipt - CET",            key:"exam_receipt_cet",     hidden:false},
    {value:"Exam Receipt - Shipside/Tailgate",key:"exam_receipt_ss_tg", hidden:false},
    {value:"Chassis Per Diem Receipt",      key:"chassis_per_diem_receipt",hidden:false},
    {value:"Warehouse Cross Dock Receipt",  key:"wh_cross_dock_receipt", hidden:false},
    {value:"Trucking Receipt",              key:"trucking_receipt",      hidden:false},
    {value:"Terminal Receipt",              key:"terminal_receipt",      hidden:false},
    {value:"Reefer Receipt",                key:"reefer_receipt",        hidden:false},
    {value:"Arrival Notice Receipt",        key:"an_receipt",            hidden:false},
    {value:"Commercial Invoice/Packing List",key:"combo_ci_pl",          hidden:false},
    {value:"Packing List/Weight List",      key:"combo_pl_wl",           hidden:false},
    {value:"ADD/CVD Disclaimer",            key:"add_cvd_disclaimer",    hidden:false},
    
    {value:"Certificate of Compliance",     key:"cert_compliance",      hidden:false},
    {value:"True Source Report",            key:"report_true_source",   hidden:false},
    {value:"Freight Invoice",               key:"freight_inv",          hidden:false},
    {value:"NOP Affidavit",                 key:"nop_affidavit",        hidden:false},
    {value:"Overtime Receipt",              key:"ot_receipt",           hidden:false}
];
