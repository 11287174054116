<div class="modal-header">
  <h4 class="modal-title">OKM Document : <span class="badge badge-warning">{{data?.path}}</span></h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div *ngIf="data != null" class="row">

    <div class="col-3">
      <ul class="list-group">
        <li class="list-group-item list-group-item-action flex-column" *ngFor="let d of data.documents;" (click)="view(d)" [class.active]="selectedItemId == d.id">{{d.name}}</li>
      </ul>
    </div>

    <div class="col-9">

      <ng-template #docLoading>
        <img src="./assets/loading.gif" alt="loading" style="width: 100%; height: 100%"/>
      </ng-template>

      <div *ngIf="docDataLoading; then docLoading;"></div>

      <div *ngIf="selectedItemId !== null">
        <iframe id="okmdoc-view" [src]="docData" frameborder="0" width="100%" height="100%" style="min-height: 600px; border: none;"></iframe>
      </div>
    </div>

  </div>

  <div *ngIf="data == null">
    <h3 class="alert alert-danger">No Current Document Data!</h3>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-dark" (click)="modal.close('Close click')">Close</button>
</div>
