
        <ng-template #eaContent>
            <ul class="list-group">
                <li class="list-group-item p-0" *ngFor="let e of ea">
                    <div class="badge badge-danger" style="width: 100%;" title="Remove {{e.entry.broker_number}} from all pages.">
                        <span>{{e.entry.broker_number}}</span>
                        <a href="#" (click)="entryRemove(e,$event)" class="entry-remove badge pull-right"><i class="fa fa-remove"></i></a>
                    </div>
                </li>
            </ul>
        </ng-template>

        <a class="btn btn-sm btn-outline-danger" placement="bottom" [ngbPopover]="eaContent" container="body" triggers="mouseenter" [autoClose]="false">
            <span>Entries</span> <span>({{ea.length}})</span> :
        </a>
    