<div id="optionsPanel" class="option-panel" [ngbCollapse]="!optionsOpen">
  <ngb-tabset [destroyOnHide]="false">

    <ngb-tab title="Division">
      <ng-template ngbTabContent>
        <div class="count-body" style="max-height: 450px; overflow-y: scroll">
          <table class="table table-sm table-bordered table-striped">
            <tbody>
            <tr *ngFor="let f of getDivisions()">
              <td><input type="checkbox" [checked]="filterExists('division',f.id)" (change)="updateFilter($event,'division',f.id)"/></td>
              <td><span >{{f.label}}</span></td>
              <td><span >XX</span></td>
            </tr>
            </tbody>
          </table>
        </div>

      </ng-template>
    </ngb-tab>

    <ngb-tab title="Folder">
      <ng-template ngbTabContent>
        <div class="count-body" style="max-height: 450px; overflow-y: scroll">
          <table class="table table-sm table-bordered table-striped">
            <tbody>
            <tr *ngFor="let f of getFolderCounts()">
              <td><input type="checkbox" [checked]="filterExists('mail_folder',f.mail_folder)" (change)="updateFilter($event,'mail_folder',f.mail_folder)"/></td>
              <td><span >{{f.mail_folder}}</span></td>
              <td><span >{{f.count}}</span></td>
            </tr>
            </tbody>
          </table>
        </div>

      </ng-template>
    </ngb-tab>

    <ngb-tab title="Domain">
      <ng-template ngbTabContent>
        <div class="count-body" style="max-height: 450px; overflow-y: scroll">
          <table class="table table-sm table-bordered table-striped">
            
            <tbody>
            <tr *ngFor="let d of getDomainCounts()" >
              <td><input type="checkbox" [checked]="filterExists('domain',d.domain)" (change)="updateFilter($event,'domain',d.domain)"/></td>
              <td><span >{{d.domain}}</span></td>
              <td>{{d.count}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="Mail Date">
      <ng-template ngbTabContent>
        <div class="count-body" style="max-height: 450px; overflow-y: scroll">
          <table class="table table-sm table-bordered table-striped">
            <tbody>
            <tr *ngFor="let d of getDateCounts()">
              <td><input type="checkbox" [checked]="filterExists('mail_date',d.mail_date)" (change)="updateFilter($event,'mail_date',d.mail_date)"/></td>
              <td><span >{{d.mail_date}}</span></td>
              <td>{{d.count}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </ngb-tab>

  </ngb-tabset>
</div>
