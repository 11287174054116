import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {EntryAssignment, EntryAssignmentContext, PageItem} from "../mailbox-component/mailbox.service";
import * as _ from 'lodash';
import {AssignmentContext} from "./assignment.context";
import {GoldenLayoutComponentState, GoldenLayoutContainer} from "@embedded-enterprises/ng6-golden-layout";
import * as GoldenLayout from "golden-layout";
import {DocType, docTypes} from '../mailbox-component/mailbox.model';

@Component({
  selector: 'entry-assign-context',
  template: `
  
    <ng-template #docSelect>
      <select class="form-control form-control-sm"  (change)="docTypeSelect($event.target.value)"  >
        <option [selected]="item.document_type === null" disabled >Document Type</option>
        <option *ngFor="let t of types" [value]="t.key" [selected]="t.key == item.document_type?.toLowerCase()">{{t.value}}</option>
      </select>
    </ng-template>

    <ng-template #billSelect>
      <ng-multiselect-dropdown
          [data]        ="itemList"
          [settings]    ="settings"
          [(ngModel)]   ="selectedList"
          [placeholder] ="(mode== EntryAssignType.BILL) ? 'Select Bill' : 'Select Container' "
          (onSelect)    ="onItemSelect($event)"
          (onSelectAll) ="onItemSelectAll($event)"
          (onDeSelect)  ="onItemDeselect($event)"
          (onDeSelectAll)="onItemDeselectAll($event)"
      >
      </ng-multiselect-dropdown>
    </ng-template>
    
    <ng-template #default>
      <div class="form-default">
        <div *ngTemplateOutlet="docSelect"></div>
        <div *ngIf="showSelect">
          <div *ngTemplateOutlet="billSelect"></div>
        </div>
      </div>
     
    </ng-template>

    <ng-template #horizonalLayout>
      <div class="form-inline">
        <div *ngTemplateOutlet="docSelect"></div>
        <div *ngIf="showSelect" style="width: 180px; margin-left: 6px;">
          <div *ngTemplateOutlet="billSelect"></div>
        </div>
      </div>
    </ng-template>
    
    <div>
      <ng-container *ngIf="horizontal; then horizonalLayout else default"></ng-container>

    </div>
  `,
  styles:[`
    
    .form-inline select{
      width: 180px;
    }
  `]
})
export class EntryAssignContextComponent implements OnInit {
  
  @Input()
  item : PageItem;
  
  @Input()
  types : Array<DocType> =docTypes;

  @Input()
  horizontal:Boolean = false;
  
  //@Input()
  mode:number;
  
  showSelect: boolean = false;
  
  @Output()
  entryContextItemChange:EventEmitter<Array<EntryAssignment>> = new EventEmitter();
  
  @Output()
  pageTypeEvent : EventEmitter<{item:PageItem,type:DocType}> = new EventEmitter();
  
  settings:object;
  
  itemList:Array<EntryAssignmentContext> = [];
  
  selectedList:Array<EntryAssignmentContext> = [];
  
  EntryAssignType;
  
  constructor(@Inject(GoldenLayoutComponentState) public state: any,
              @Inject(GoldenLayoutContainer) private container: GoldenLayout.Container, private cd :ChangeDetectorRef) {
    
    this.EntryAssignType = EntryAssignType;
    
    this.settings = {
      singleSelection   : false,
      idField           : 'id',
      textField         : 'text',
      selectAllText     : 'Select All',
      unSelectAllText   : 'UnSelect All',
      itemsShowLimit    : 0,
      allowSearchFilter : false
    };
  
  
    this.container.layoutManager.eventHub.on("entrySelect",(ea:EntryAssignment)=>{
    
    });
    
  }

  
  ngOnInit() {

    this.mode = EntryAssignType.mode(this.item.document_type);
    this.selectedList = [];

    this.types = this.getSortedDocTypes();
    
    this.showSelectList();
    
    if(this.mode == EntryAssignType.BILL){
      
      this.itemList = this.getBillSelectItems();
    
      this.item.entry_assignment
        .filter(e => e.data != null && e.data.length > 0)
        .forEach(e => e.data.forEach(d => {
          this.selectedList = this.selectedList.concat(Object.assign({}, d));
        }))
      ;
    }
  
  
    if(this.mode == EntryAssignType.CONTAINER){
      
      this.itemList = this.getContainerSelectItems();
  
      this.item.entry_assignment
        .filter(e => e.data != null && e.data.length > 0)
        .forEach(e => e.data.forEach(d => {
          this.selectedList = this.selectedList.concat(Object.assign({}, d));
        }))
      ;
    }
    
    if(this.mode == null){
      this.showSelect = false;
    }
  
    
  }
  
  
  /**
   *
   * @param {Array<EntryAssignment>} items
   */
  setData(items:Array<EntryAssignment>){
    //this.entryAssignment = items;
    this.item.entry_assignment = items;
    this.ngOnInit();
  }


  getSortedDocTypes() : Array<DocType> {
    return this.types.sort((a,b) => {
      if(a.value < b.value){return -1;}
      if(a.value > b.value){return 1;}
      return 0;
    });
  }

  
  showSelectList(){
    
    if(this.item.entry_assignment.length > 0){
      if(this.mode == EntryAssignType.BILL || this.mode == EntryAssignType.CONTAINER){
  
        this.showSelect = true;
      }
    }
    
  }
  
  setSelected(selectedList:Array<EntryAssignmentContext>){
    this.selectedList = this.selectedList;
  }
  
  
  setItemList(itemList:Array<EntryAssignmentContext>){
    this.itemList = itemList;
  }
  
  
  addSelected(context:EntryAssignmentContext){
    
    let e:EntryAssignmentContext = this.selectedList.find(s => s.ref_id == context.ref_id);
    
    if(e){return false;}
    
    this.selectedList.push(context);
  }
  
  
  getSelected(){
    return this.selectedList;
  }
  
  
  /**
   *
   * @param e
   */
  docTypeSelect(e){
    
    this.clear();

    let t:DocType = this.types.find((d) => d.key==e);

    this.item.document_type = e;
    this.mode = EntryAssignType.mode(this.item.document_type);
    
    this.ngOnInit();

    this.pageTypeEvent.emit({
      item: this.item,
      type: t
    });
  }
  
  
  /**
   *
   * @returns {Array<EntryAssignmentContext>}
   */
  getContainerSelectItems(){
    
    let data:Array<EntryAssignmentContext> = [];
    
    this.item.entry_assignment.forEach((e) =>{
      
      data = data.concat(AssignmentContext.containers(e));
      
    });
    
    return data;
  }
  
  
  /**
   *
   * @returns {Array<EntryAssignmentContext>}
   */
  getBillSelectItems(){
  
    let data:Array<EntryAssignmentContext> = [];
    
    this.item.entry_assignment.forEach((e) =>{
  
      data = data.concat(AssignmentContext.bills(e));
      
    });
    
    return data;
  }
  
  
  /**
   *
   * @param {{id: string; text: string}} i
   */
  onItemSelect (i:{id:string,text:string}) {
    
    let context:EntryAssignmentContext = this.itemList.find((c) =>{
      return c.id == i.id
    });
  
    
    let ea:EntryAssignment = this.item.entry_assignment.find((e) =>{
      return e.entry.entry_id == context.entry_id
    });
    
    
    ea.context = (this.mode == EntryAssignType.BILL) ? "bill" : "container";
    
    ea.data = ea.data || [];
    ea.data.push(context);
  
    this.entryContextItemChange.emit(this.item.entry_assignment);
  }
  
  
  /**
   *
   * @param {Array<{id: string; text: string}>} all
   */
  onItemSelectAll(all:Array<{id:string,text:string}>){

    //this.selectedList = [];
    this.item.entry_assignment.forEach(e =>{e.data = null;});

    all.forEach((i) => {

      let context:EntryAssignmentContext = this.itemList.find((c) => c.id == i.id);

      let ea:EntryAssignment = this.item.entry_assignment.find((e) => e.entry.entry_id == context.entry_id);

      ea.context = (this.mode == EntryAssignType.BILL) ? "bill" : "container";
      ea.data = ea.data || [];
      ea.data.push(context);

    });

    this.entryContextItemChange.emit(this.item.entry_assignment);
  }
  
  
  /**
   *
   * @param {{id: string; text: string}} i
   */
  onItemDeselect(i:{id:string,text:string}){

    let context:EntryAssignmentContext = this.itemList.find((c) =>{
      return c.id == i.id
    });
    
    this.item.entry_assignment
      .filter(e => {return e.entry.entry_id == context.entry_id})
      .map(i => {
        
        i.data = i.data.filter(d =>{
          return d.id != context.id;
        });
        
        if(i.data.length == 0){i.context = "entry";}
        
      });
  
    this.entryContextItemChange.emit(this.item.entry_assignment);
  }
  
  
  /**
   *
   * @param e
   */
  onItemDeselectAll(e){
    this.selectedList = [];
    
    this.item.entry_assignment.forEach(e =>{
      e.data = null;
      e.context = "entry";
    });
    
    this.entryContextItemChange.emit(this.item.entry_assignment);
  }
  
  
  public clear(){
    this.mode = null;
    this.selectedList = [];
    this.itemList = [];
    this.item.entry_assignment.forEach(e =>{e.data = null;});
  }

}



export class EntryAssignType{
  
  public static BILL       = 1;
  
  public static CONTAINER  = 2;
  
  public static billTypes = [
    "ARRIVAL_NOTICE",
    "LINE_ARRIVAL_NOTICE",
    "BOL",
    "DEBIT_NOTE",
    "AN_RECEIPT"
  ];
  
  public static containerTypes = [
    "PHYTO",
    "FORM_203",
    "USDA_AMS_INSPECTION_CERT",
    "PICKUP_NUMBER",
    "EXAM_RECEIPT_XRAY",
    "EXAM_RECEIPT_AQI",
    "EXAM_RECEIPT_CET",
    "EXAM_RECEIPT_SS_TG",
    "CHASSIS_PER_DIEM_RECEIPT",
    "WH_CROSS_DOCK_RECEIPT",
    "TRUCKING_RECEIPT",
    "TERMINAL_RECEIPT",
    "REEFER_RECEIPT"

  ];
  
  public static mode(docType:string){
    
    if(_.includes(this.billTypes, docType.toUpperCase())){
      return EntryAssignType.BILL;
    }
  
    if(_.includes(this.containerTypes, docType.toUpperCase())){
      return EntryAssignType.CONTAINER;
    }
    
    return null;
  }
  
}
