<div class="card drag page-item" style="margin-bottom: 10px;" >
    <!--{{item.id}}-->
    <page-assigned-entries [ea]="item.entry_assignment" (removeItem)="entryItemRemove($event)">
    </page-assigned-entries>

    <div *ngIf="isPdf()" style="max-height: 120px; overflow: hidden">

        <img *ngIf="imageData != null" style="width: 100%"
             [src]="imageData" height="225" dnd-draggable
             data-id="{{item.id}}"
             (click)="pageClick($event)"
             [id]="item.id"
             [dragData]="item"
             (onDragStart)="dragStart()"
             (onDragEnd)="dragEnd()"/>
    </div>

    <!---- Excel/Doc icon thumb ---->
    <div *ngIf="!isPdf()">

        <!---- Excels use the MailItem instance to display assigned entries ----->
        <page-assigned-entries [ea]="mailItem.entry_assignment" (removeItem)="entryMailItemRemove($event)">
        </page-assigned-entries>

        <div class="badge badge-success" style="width: 100%; min-height: 170px;" >
            <span class="fa fa-file-excel-o" style="font-size: 62px; position: relative; top: 50px;" (click)="pageClick($event)"></span>
            <a [href]="excelUrl(item)" target="_blank" style="position: absolute;bottom: 145px;right: 10px;" class="text-white">
                <span class="fa fa-download" ></span>
            </a>

        </div>
    </div>


    <div class="card-body">
        <div class="card-text">

            <!---- Document type selection & bill/container context----->
            <entry-assign-context
                    [item]="item"
                    (entryContextItemChange)="entryAssignmentChange($event)"
                    (pageTypeEvent)="docTypeSelect($event)"
                    #eacontext>
            </entry-assign-context>

            <hr>

            <div class="form-inline">
                <div class="form-check-inline">
                    <input type="checkbox" class="form-check-input" [checked]="item.revised" (change)="revisedChange()">
                    <label class="form-check-label" >Revised</label>
                </div>

                <div class="form-check-inline">
                    <input type="checkbox" class="form-check-input" [checked]="item.hidden" (change)="hiddenChange()">
                    <label class="form-check-label" >Hidden</label>
                </div>
            </div>

            <br/>

        </div>


        <div *ngIf="isPdf()">

            <div class="drop drop-items" dnd-droppable (onDropSuccess)="dropEvent($event)" [allowDrop]="allowDropItem()">

                <div *ngIf="item.combine_items.length>0">
                    <div *ngFor="let a of item.combine_items" class="pull-left" style="display: inline-block; width:40px; height:65px; margin-right: 2px">
                        <page-img-sm [page]="a" (uncombineEvent)="uncombine($event)" (click)="combinePageClick(a)"></page-img-sm>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="page-item-select">
            <page-check [checked]="item.selected" (change)="item.selected = !item.selected"></page-check>
        </div>
    </div>
    <div>{{trancateId(item)}}</div>
</div>
