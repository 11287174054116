import {Component, Injectable, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DocumentResult, FolderResult, OpenkmDataService} from "./openkm-data.service";
import {DomSanitizer} from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: 'app-openkm-data',
  templateUrl: './openkm-data.component.html',
  styleUrls: ['./openkm-data.component.css']
})
export class OpenkmDataComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}


@Component({
  selector: 'openkm-mocal',
  templateUrl: './modal.html',
  styleUrls: ['./openkm-data.component.css']
})
export class OpenkmModal implements OnInit{
  
  @Input() data: FolderResult;
  
  selectedItemId:string = null;

  docData;

  docDataLoading:boolean = false;
  
  ngOnInit(): void {
    //$(()=>{ $(".modal-dialog").draggable({handle: ".modal-header"}); });
  }
  
  constructor(public modal: NgbActiveModal, private okm:OpenkmDataService,private sanitizer: DomSanitizer) {}
  
  
  view(doc:DocumentResult){
    
    if(this.selectedItemId == doc.id){return false;}
    
    this.selectedItemId = doc.id;

    this.okm.getDocumentData(doc.id).then((data:Blob) => {
      let blob: any = new Blob([(data)], { type: 'application/pdf' });
      let blobUrl= URL.createObjectURL(blob);
      this.docData = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      this.docDataLoading = false;
    });
    
  }


  isLoading(){
    return (this.docDataLoading && this.selectedItemId != null) ? true: false;
  }
  
}

