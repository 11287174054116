import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from "rxjs/Observable";

@Injectable()
export class FreshlookDataService{
  
  
  private host:string;
  
  constructor(private http: HttpClient){
    this.host = environment.host;
  }
  
  
  /**
   *
   * @param {FreshlookSearchParams} data
   * @returns {Observable<EntryResult[]>}
   */
  public findEntries(data:FreshlookSearchParams) {
    
    return this.http.post<EntryResult[]>(this.host + "/freshlook/entry/search",data)
        .toPromise()
      //.map((res) => {return res.json();})
      .catch(this.err)
      ;
    
  }
  
  
  /**
   *
   * @param {FreshlookWSSearchParams} params
   * @returns {Observable<EntryResult[]>}
   */
  public findEntriesWS(params:FreshlookWSSearchParams) : Observable<EntryResult[]>{
  
  
    let data = {
      "u" : environment.fl.u,
      "k" : environment.fl.k,
      "g" : environment.fl.g,
      "sg": environment.fl.sg
    };
    
    Object.keys(params)
      .filter(k => params[k] != null)
      .forEach((key,i) =>{ data[key] = params[key];})
    ;
    
    return this.http.get<FreshlookWSResult>(environment.fl.url,{params:data})

      .map((res) => {
      
        //let r = <FreshlookWSResult> res;
        
        return res.items.map(this.mapEntryResult);
        
      })
      .catch(this.err)
      ;
    
  }
  
  
  private err (error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
  
  
  /**
   *
   * @param f
   */
  private mapEntryResult(f:FreshlookWSResultBody) : EntryResult{
    
    return <EntryResult>{
      broker_number   : f.broker,
      division        : f.div,
      year            : f.year,
      container       : f.cont,
      bol             : f.bl,
      entry_id        : parseInt(f.uid),
      entry_number    : f.entry,
      short_name      : f.cust,
      cust            : f.cust,
      company         : f.comp,
      shipper         : f.shipper,
      vessel          : f.vf,
      eta             : f.eta,
      eta2            : f.eta2,
      ff              : Boolean(parseInt(f.ff)),
      company_id      : parseInt(f.comp_id),
      container_group : f.cont_group,
      bill_group      : f.bl_group,
      doc_group       : f.doc_group
    };
    
  }
  
}



export class EntryResult{
  public entry_id       : number;
  public broker_number  : string;
  public year           : string;
  public entry_number   : string;
  //public customer_id : number;
  public company        : string;
  public short_name     : string;
  public cust           : string;
  //public unique_entry : string;
  public company_id     : number;
  public division       : string;
  public shipper        : string;
  public vessel         : string;
  public eta            : string;
  public eta2           : string;
  public ff             : boolean;
  //public division_id : number;
  //public gms_company_index : string;
  public container      : string;
  public bol            : string;
  public container_group: Array<{id:number, value:string}>;
  public bill_group     : Array<{id:number, value:string, type:string}>;
  public doc_group      : Array<FreshlookDocGroupItem>
}


export interface FreshlookSearchParams{
  customer  : string;
  company   : string;
  division  : string;
  broker    : string;
  bol       : string;
  container : string;
  entry     : string;
}



export interface FreshlookWSSearchParams{
  archive : number;   //use archive
  days    : number;   //archive days
  comp    : string;   //company
  div     : string;   //division
  co      : string;   //country of origin
  broker  : string;   //broker number
  entry   : string;   //entry no
  cust    : string;   //customer
  com     : string;   //commodity/remarks
  vf      : string;   //vessel/flight
  tmnt    : string;   //treatment
  cont    : string;   //container
  bl      : string;   //bill of lading
  inv     : string;   //invoice no
  uid     : number;   //unique entry id
  comp_id : number;   //company id
  div_id  : number;   //division id
  comb1   : string;
  comb2   : string;
  comb3   : string;
  comb4   : string;
  comb5   : string;
  shipper : string;
}



export interface FreshlookWSResult{
  limit : number;
  items : Array<FreshlookWSResultBody>;
}


export interface FreshlookWSResultBody{
  uid         : string;
  year        : string;
  comp_id     : string;
  comp        : string;
  div_id      : number;
  div         : string;
  broker      : string;
  cust        : string;
  entry       : string;
  com         : string;
  tmnt        : string;
  eta         : string;
  eta2        : string;
  ff          : string;
  cbp_rel     : string;
  vf          : string;
  co          : string;
  cont        : string;
  bl          : string;
  inv         : string;
  shipper     : string;
  cont_group  : Array<{id:number, value:string}>;
  bl_group    : Array<{id:number, value:string, type:string}>;
  inv_group   : Array<{id:number, value:string, active:string, open:string, balance:number}>;
  doc_group   : Array<FreshlookDocGroupItem>
}


export interface FreshlookDocGroupItem {
  id:number;
  type:string;
  name:string;
  okm_name:string;
  okm_uuid:string;
  revised:number;
  bol:string;
  container:string;
}
