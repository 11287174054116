import {Component} from '@angular/core';


@Component({
    selector:"test-layout",
    template:`
        <div class="row">
            <div class="col-3 ">
                <item-list></item-list>
            </div>
            <div class="col-9">
                body
            </div>
        </div>
    `
})
export class TestLayout{

}
