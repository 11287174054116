import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {
  EntryResult, FreshlookDataService, FreshlookDocGroupItem, FreshlookSearchParams,
  FreshlookWSSearchParams
} from "./freshlook-data.service";
import {OpenkmModal} from "../openkm-data/openkm-data.component";
import {FolderResult, OpenkmDataService} from "../openkm-data/openkm-data.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GoldenLayoutComponentState, GoldenLayoutContainer} from "@embedded-enterprises/ng6-golden-layout";
import * as GoldenLayout from 'golden-layout';
import {EntryAssignment} from "../mailbox-component/mailbox.service";




@Component({
  selector: 'freshlook-search',
  templateUrl: './freshlook-search.html',
  styleUrls: ['./freshlook-data.css']
})
export class FreshlookEntrySearch implements OnInit {
  
  public customer:any;
  public company:any;
  public division:any;
  public entry:any;
  public broker:any;
  public bol:any;
  public container:any;
  
  /** Broker#  OR Entry# OR Customer **/
  public comb1:any;
  
  /** * Container OR Bill of Lading **/
  public comb2:any;
  
  /** Vessel/Fight OR ETA1 Date (YYYY-MM-DD hh:mm:ss) OR ETA2 Date (m/d/yy) **/
  public comb3:any;
  
  /** Customer Or Shipper **/
  public comb4:any;
  
  
  /** Broker#  OR Entry# **/
  public comb5:any;
  
  /** MID Name(s) or ISF Selling Party **/
  public shipper:any;
  
  public entries: EntryResult[];
  
  public selected = [];

  public useArchive:boolean = false;

  public archiveDays:any = 60;
  
  @Output()
  public item: EventEmitter<EntryAssignment> = new EventEmitter();
  
  @Output()
  public brokerClickEvent: EventEmitter<EntryResult> = new EventEmitter();
  
  
  public eaHistory:Array<EntryAssignment> ;
  
  constructor(
    private modal: NgbModal,
    private okm: OpenkmDataService,
    private fl:FreshlookDataService,
    @Inject(GoldenLayoutComponentState) public state: any,
    @Inject(GoldenLayoutContainer) private gcontainer: GoldenLayout.Container,
    
  ) {}
  
  
  /**
   *
   */
  ngOnInit() {
    this.eaHistory = <Array<EntryAssignment>>JSON.parse(localStorage.getItem("entry-assign-history")) || new Array<EntryAssignment>();
    
  }
  
  
  /**
   *
   * @returns {FreshlookSearchParams}
   */
  params() : FreshlookSearchParams{
    return {
      "customer"  : this.customer || null,
      "company"   : this.company || null,
      "division"  : this.division || null,
      "entry"     : this.entry || null,
      "broker"    : this.broker || null,
      "bol"       : this.bol || null,
      "container" : this.container || null
    };
  }
  
  
  /**
   *
   * @returns {FreshlookWSSearchParams}
   */
  paramsWS() : FreshlookWSSearchParams{
    return {
      "archive" : (this.useArchive == true) ? 1 : null,
      "days"    : (this.useArchive == true) ? this.archiveDays : null,
      "cust"    : this.customer || null,
      "comp"    : this.company || null,
      "div"     : this.division || null,
      "co"      : null,
      "entry"   : this.entry || null,
      "broker"  : this.broker || null,
      "bl"      : this.bol || null,
      "cont"    : this.container || null,
      "comb1"   : this.comb1 || null,
      "comb2"   : this.comb2 || null,
      "comb3"   : this.comb3 || null,
      "comb4"   : this.comb4 || null,
      "comb5"   : this.comb5 || null,
      "shipper" : this.shipper || null,
      "com"     : null,
      "vf"      : null,
      "tmnt"    : null,
      "inv"     : null,
      "uid"     : null,
      "comp_id" : null,
      "div_id"  : null
    };
  }
  
  
  /**
   *
   * @param {boolean} checked
   * @param {EntryResult} entry
   */
  itemSelect(checked:boolean, entry: EntryResult){
    
    if(!checked){
      this.selected = this.selected.filter((e) => JSON.stringify(e) != JSON.stringify(entry));
      return;
    }
    
    this.selected.push(entry);
  }
  
  
  /**
   *
   * @returns {Array<EntryResult>}
   */
  public getSelected() : Array<EntryResult>{
    return this.selected;
  }
  
  
  /**
   *
   */
  search(){
    
    this.fl.findEntriesWS(this.paramsWS()).subscribe((r) =>{
      this.entries = r;
    });
  }
  
  
  /**
   *
   * @param {EntryResult} item
   * @param {string} context
   */
  entrySelected(entry:EntryResult,context:string){
    
    //let ea:EntryAssignment = {"context" : context, entry:entry, data:[]};
    let ea:EntryAssignment = new EntryAssignment(context, entry);
    this.updateHistory(ea);
  
    this.item.emit(ea);
  }
  
  
  
  updateHistory(ea:EntryAssignment){
    this.eaHistory.push(ea);
  
    this.eaHistory = this.eaHistory.reverse();
  
    if(this.eaHistory.length > 10){
    
      this.eaHistory.pop();
    }
    
    localStorage.setItem("entry-assign-history",JSON.stringify(this.eaHistory));
  
    this.gcontainer.layoutManager.eventHub.emit("entrySelect",ea);
  }
  
  
  /**
   *
   */
  clearParams(){
    this.customer = null;
    this.company = null;
    this.division = null;
    this.entry = null;
    this.broker = null;
    this.bol = null;
    this.container = null;
    this.comb1 = null;
    this.comb2 = null;
    this.comb3 = null;
    this.comb4 = null;
    this.comb5 = null;
    this.shipper = null;
    
    this.entries = null;
  }
  
  
  /**
   *
   * @param {string} val
   */
  liveSearch(val:string){
    
    if(val.length > 2){
      this.search();
    }
    
  }
  
  
  /**
   *
   * @param ev
   */
  keyPress(ev: KeyboardEvent){
    
    if(ev.code == "Enter" || ev.code == "NumpadEnter"){
      
      let el:HTMLInputElement = <HTMLInputElement>ev.target;
      
      if(el.value.length > 2){
        this.search();
      }
    }
    
  }
  
  
  /**
   * @param {EntryResult} e
   */
  brokerClick(ev:Event, e:EntryResult){
    ev.preventDefault();
    
    this.brokerClickEvent.emit(e);
    
    this.showOkmDocModal(e);
  }
  
  
  /**
   *
   * @param {EntryResult} e
   */
  showOkmDocModal(e:EntryResult){
    
    let path:string = e.company.toLocaleLowerCase() + "/" + e.year + "/" + e.division + "/" + e.broker_number.substr(4,2) + "/" + e.broker_number;
    
    this.okm.getBrokerFolder(path).then((res:FolderResult) => {
      
      const ref =  this.modal.open(OpenkmModal,{backdrop:"static",size:"lg"});
      
      ref.componentInstance.data = res;
      
    });
    
  }


  /**
   *
   * @param el
   */
  split(el:any){
    if(el === null || el === ""){return ""}
    
    return el.split(",").join('\n');
  }


  /**
   *
   */
  checkArchiveDays(){
    this.archiveDays = this.archiveDays.replace(/\D/g,'');
  }
  
}

@Component({
  selector: 'current-docs',
  styleUrls: ['./freshlook-data.css'],
  template: `
    <ul class="list-inline current-docs-list">
      <li *ngFor="let i of items" class="list-inline-item" title="{{i.type}} {{i.bol}} {{i.container}}" style="margin-right: 2px;">
        <span class="badge {{docTypes.get(i.okm_name)}}">
          <i class="fa fa-check-circle"><strong *ngIf="i.revised==1">&nbsp;R</strong></i>
        </span>
      </li>
    </ul>
  `
})
export class CurrentEntryDocs implements OnInit{
  
  docTypes = new Map([
    ['phyto',             'badge-phyto'],
    ['arrival_notice',    'badge-arrival'],
    ['bol',               'badge-bl'],
    ['isf_template',      'badge-isf'],
    ['cold_treatment',    'badge-usda'],
    ['commercial_invoice','badge-invoice'],
    ['form_203',          'badge-203'],
    ['cert_of_origin',    'badge-origin']
  ]);
  
  
  @Input()
  docGroup:Array<FreshlookDocGroupItem>;
  
  items:Array<FreshlookDocGroupItem>;
  
  ngOnInit(): void {
    this.items = this.docGroup
      .filter(d => this.docTypes.has(d.okm_name))
      .sort()
    ;
  }
  
  dt(key:string){
    if(this.docTypes.has(key)){
      return this.docTypes.get(key);
    }
  }
  
  
}


@Component({
  selector: 'assign-history',
  templateUrl: './assign-history.html',
  styleUrls: ['./freshlook-data.css']
})
export class EntryAssignmentHistory implements OnInit{
  
  
  public eaHistory:Array<EntryAssignment> ;
  
  constructor(private modal: NgbModal,
              private okm: OpenkmDataService,
              @Inject(GoldenLayoutComponentState) public state: any,
              @Inject(GoldenLayoutContainer) private gcontainer: GoldenLayout.Container) {
    
    this.gcontainer.layoutManager.eventHub.on("entrySelect",()=>{
      this.getHistory();
    });
  }
  
  
  ngOnInit(): void {
    this.getHistory();
  }
  
  
  
  private getHistory(){
    this.eaHistory = <Array<EntryAssignment>>JSON.parse(localStorage.getItem("entry-assign-history")) || new Array<EntryAssignment>();
    
  }
  
  
  public entrySelected(ea:EntryAssignment){
    this.gcontainer.layoutManager.eventHub.emit("entrySelect",ea);
  }
  
  
  /**
   * @param {EntryResult} e
   */
  brokerClick(ev:Event, e:EntryResult){
    ev.preventDefault();
    
    let path:string = e.company.toLocaleLowerCase() + "/" + e.year + "/" + e.division + "/" + e.broker_number;
  
    this.okm.getBrokerFolder(path).then((res:FolderResult) => {
    
      const ref =  this.modal.open(OpenkmModal,{backdrop:"static",size:"lg"});
    
      ref.componentInstance.data = res;
    
    });
  }
  
  
  
  split(el:any){
    if(el === null || el === ""){return ""}
    
    return el.split(",").join('\n');
  }
  
  
}
