
        <div style="margin: 0 auto;">

            <div *ngIf="checked==true; then ch_on; else ch_off;"></div>

            <ng-template #ch_on>
                <a href="#" (click)="checkChange($event)" class="badge badge-primary page-cb">
                    <i class="fa fa-check-square"></i><span *ngIf="label != null">&nbsp;{{label}}</span>
                </a>
            </ng-template>

            <ng-template #ch_off>
                <a href="#" (click)="checkChange($event)" class="badge badge-secondary page-cb">
                    <i class="fa fa-square"></i><span *ngIf="label != null">&nbsp;{{label}}</span>
                </a>
            </ng-template>

        </div>
    