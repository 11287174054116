

<div id="mailitem-list-panel" >


    <table class="table table-sm mb-0">
        <tr>
            <td>
                <status-select #status [(selectedItem)]="listFilter.status[0]" (selectedItemChange)="mailboxListStatusSelected($event)" [allOption]="false"></status-select>
            </td>
            <td>
                <div class="input-group">
                    
                    <input type="text" class="form-control form-control-sm " 
                        [ngbTooltip]="filterToolTip"    placement="bottom"  tooltipClass="filterToolTip"
                        placeholder="Filter"            aria-label="Filter" 
                        [(ngModel)]="listFilter.term"   (keypress)="filterMailItems($event)"
                        [class.isFiltering]="isFiltering || listFilter.filter.length > 0 || listFilter.term != null"
                        >
                    
                    <div class="input-group-append">
                        <button class="btn btn-sm btn-danger" type="button" (click)="clearFilter()"><i class="fa fa-remove"></i></button>
                    </div>
                </div>
            </td>
            <td>
                <button class="btn btn-sm btn-secondary" aria-controls="optionsPanel" (click)="optionsOpenEvent($event)" [attr.aria-expanded]="!optionsOpen">
                    <i class="fa fa-gears"></i>
                </button>
            </td>
        </tr>
    </table>

    <!--- Option panel ---->
    <mailbox-filter #mailfilter [status]="getCurrentStatus()" [optionsOpen]="optionsOpen"  [(filterFields)]="listFilter.filter" (filterFieldsChange)="filterField($event)"></mailbox-filter>


    <table class="table table-sm" style="margin:0">
        <tr>
            <td>
                <div class="">
                    <a href="#" [class]="multiSelect ? 'badge badge-info' : 'badge badge-secondary' " style="font-size: 10px;" (click)="$event.preventDefault(); multiSelect = !multiSelect">
                        Multi Move&nbsp;<i class="fa fa-paperclip"></i>
                    </a>
                </div>
            </td>
            <td class="float-right">
                <div class="form-inline">

                    &nbsp;
                    <mailitem-sort class="" (sortDirectionChange)="sortChange($event)"></mailitem-sort>
                    &nbsp;
                    <a href="#" (click)="listGroupChange($event)">
                        <span *ngIf="!listFilter.grouped" style="font-size: 10px;" class="badge badge-primary"><i class="fa fa-gears"></i>&nbsp;Grouped by File</span>
                        <span *ngIf="listFilter.grouped" style="font-size: 10px;" class="badge badge-secondary"><i class="fa fa-gears"></i>&nbsp;Grouped by Email</span>
                    </a>

                </div>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <div *ngIf="multiSelect">
                    Move selected to status:<br/>
                    <status-select (selectedItemChange)="multiSelectChange($event)" [selectedItem]="itemStatus.selectedItem" #mutliMoveItem></status-select>
                </div>
            </td>
        </tr>
    </table>


    <div *ngIf="isSearching" id="spinner">
        <span><i class="fa fa-search"></i> Loading...</span>
    </div>

    <div *ngIf="!optionsOpen" >

        <div style="max-height: 600px; overflow-y: scroll;">

            <table class="table table-sm table-striped table-hover mail-item-list">
                <tbody>
                <tr *ngFor="let item of data?.content;let i = index" (click)="viewItem(i)" [class.table-primary]="listSelection(item)">
    
                    <td *ngIf="multiSelect">
                        <input [value]="i" #multisel type="checkbox" class="m-0 p-0"/>
                    </td>
    
                    <td style="width: 20px" class="pl-0 pt-1 pb-0">
                        <small *ngIf="item.mime_type=='pdf'; then pdfType;"></small>
                        <small *ngIf="item.mime_type=='xls' || item.mime_type=='xlsx' || item.mime_type=='csv'; then csvType;"></small>
                        <small *ngIf="item.mime_type=='doc' || item.mime_type=='docx'; then docType;"></small>
                    </td>

                    <td class="pl-0 pt-1 pb-0" title="{{item.mail_from}}">
                        <span class="small badge badge-secondary">{{item.mail_folder}}</span><br/>
                        <span class="small">{{item.domain}}</span>
                    </td>


                    <td class="pl-0 pt-1 pb-0">
                        <ng-template #divSelect>
                            <division-select [selectedItem]="item.division" (divisionSelected)="mailItemDivisionSelected(item,$event,i)"></division-select>
                        </ng-template>

                        <ng-template #div>
                            <span class="badge badge-light">{{item.division}}</span>
                        </ng-template>

                        <div *ngIf="listSelection(item); then divSelect; else div;"></div>
                    </td>

                    <td class="pl-0 pt-1 pb-0">

                        <ng-template #status>
                            <div class="input-group">
                                <div class="small">
                                    <status-select class="" [(selectedItem)]="item.status" (selectedItemChange)="mailItemStatusSelected(item,$event,i)"></status-select>
                                </div>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-success" (click)="commitItemNow()" style="padding: 1px 8px; font-size: 14px" title="Commit this now">
                                        <i class="fa fa-bolt"></i>
                                    </button>
                                </div>

                            </div>
                        </ng-template>

                        <ng-template #maildate>
                            <div class="pull-right overflow-hidden">
                                <small>{{item.mail_date +" "+item.mail_time | date:"short"}}</small>
                            </div>
                        </ng-template>

                        <div *ngIf="(listSelection(item)); then status; else maildate;"></div>
                    </td>

                </tr>

                </tbody>
            </table>
        </div>

        <div *ngIf="selected != null">
            {{selected.id}}
        </div>

        <ngb-pagination
                [collectionSize]="totalElements"
                (pageChange)="pageChange($event)"
                [maxSize]="6"
                [(page)]="listFilter.page"
                [pageSize] = "size"
                [boundaryLinks]="true"
                [rotate]="true"
                [directionLinks]="true">
        </ngb-pagination>
    </div>
    


</div>



<ng-template #pdfType>
    <span class="badge badge-danger file-icon">
      <i class="fa fa-file-pdf-o"></i>
    </span>
</ng-template>

<ng-template #docType>
    <span class="badge badge-primary file-icon">
      <i class="fa fa-file-word-o"></i>
    </span>
</ng-template>

<ng-template #csvType>
    <span class="badge badge-success file-icon">
      <i class="fa fa-file-excel-o"></i>
    </span>
</ng-template>

<ng-template #filterToolTip>

    <table class="table table-sm table-bordered table-dark filterToolTip">

        <tr *ngFor="let f of listFilter.filter">
            <td>{{f.field}}:</td>
            <td>{{f.value}}</td>
        </tr>

        <tr *ngIf="listFilter.term ">
            <td>Term:</td>
            <td>{{listFilter.term}}</td>
        </tr>

    </table>

</ng-template>
